import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {ContratMenuService} from '../../../core/services/gestioncontrats/contrat-menu.service';
import {ContratsMenusConvivesRepasService} from '../../../core/services/entities/contrats-menus-convives-repas.service';
import {RepasService} from '../../../core/services/entities/repas.service';
import {DecoupagesRepasService} from '../../../core/services/entities/decoupages-repas.service';
import {
  ContratsMenusConvivesDecoupagesService
} from '../../../core/services/entities/contrats-menus-convives-decoupages.service';
import {ModelesNutritionnelsService} from '../../../core/services/entities/modeles-nutritionnels.service';
import {GestionGemrcnService} from '../../../core/services/gestiongemrcn/gestion-gemrcn.service';
import {ContratsMenusConvivesService} from '../../../core/services/gestioncontrats/contrats-menus-convives.service';
import {combineLatest, Observable} from 'rxjs';
import {ContratMenuConviveDTO} from '../../../core/dtos/contratmenuconvive-dto';
import {PointDeLivraisonDTO} from '../../../core/dtos/point-de-livraison-d-t-o';
import {PointDeLivraisonService} from '../../../core/services/entities/point-de-livraison.service';
import {CmcPlanningResolverService, CmcPlanningSupplier} from '../cmc-planning/cmc-planning-resolver.service';
import {ContratMenuConviveDecoupage__PlcDTO} from '../../../core/dtos/contratmenuconvivedecoupage__plc-dto';
import {CmcMnService} from '../../../core/services/entities/cmc-mn.service';
import {ContratMenuConvive__ModeleNutriDTO} from '../../../core/dtos/contratmenuconvive__modele-nutri-dto';
import {ContratMenuConviveRepasDTO} from '../../../core/dtos/contratmenuconviverepas-dto';
import {DecoupageRepasDTO} from '../../../core/dtos/decoupagerepas-dto';
import {ContratMenuConviveDecoupageDTO} from '../../../core/dtos/contratmenuconvivedecoupage-dto';
import {Cmcr__CmcCa__PlcDto} from '../../../core/dtos/cmcr__cmcca__plc-dto';
import {RepasDTO} from '../../../core/dtos/repas-dto';
import {JourSemaine} from '../../../core/constants';
import {RegimeAlimentaireDTO} from "../../../core/dtos/regime-alimentaire-dto";
import {ContratMenuConvive__ContrainteAlimDTO} from "../../../core/dtos/contratmenuconvive__contrainte-alim-dto";

@Injectable({
  providedIn: 'root'
})
export class CmcPointsDeLivraisonResolverService implements Resolve<CmcPlcSupplier> {

  constructor(private gds: GenericDatagridService,
              private utils: UtilsService,
              private cmcPlanningResolverSvc: CmcPlanningResolverService,
              private contratMenuSvc: ContratMenuService,
              private cmcrSvc: ContratsMenusConvivesRepasService,
              private repasSvc: RepasService,
              private decoupageRepasSvc: DecoupagesRepasService,
              private cmcdSvc: ContratsMenusConvivesDecoupagesService,
              private pdlSvc: PointDeLivraisonService,
              private modeleNutriSvc: ModelesNutritionnelsService,
              private ggSvc: GestionGemrcnService,
              private cmcMnSvc: CmcMnService,
              private cmcSvc: ContratsMenusConvivesService) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CmcPlcSupplier> | Promise<CmcPlcSupplier> | CmcPlcSupplier => {

    const cmcPlc = new CmcPlcSupplier();
    const idContratMenuConvive: number = +route.parent.paramMap.get('idContratMenuConvive');

    const cmc$ = this.gds.getOne(this.cmcSvc.getEntityName(), idContratMenuConvive);
    const plcList$ = this.pdlSvc.getPlcListByPrestation(idContratMenuConvive);
    const cmcMnList$ = this.gds.search(this.cmcMnSvc.filterByPrestation(idContratMenuConvive));

    const all$ = combineLatest([cmc$, plcList$, cmcMnList$]);

    return all$
      .toPromise().then(response => {
        cmcPlc.contratMenuConvive = response[0].one;
        cmcPlc.pointsDeLivraison = response[1].resultList;
        cmcPlc.periodes = response[2].resultList;

        return cmcPlc;
      });
  };
}


export class CmcPlcSupplier {
  periodes: ContratMenuConvive__ModeleNutriDTO[] = [];

  pointsDeLivraison: PointDeLivraisonDTO[] = [];
  contratMenuConvive: ContratMenuConviveDTO = undefined;

  selectedPointDeLivraison: PointDeLivraisonDTO = undefined;

  cmcdPlcList: ContratMenuConviveDecoupage__PlcDTO[] = [];
  cmcrCmcCaPlcList: Cmcr__CmcCa__PlcDto[] = [];
  cmccaList: ContratMenuConvive__ContrainteAlimDTO[] = [];
  cmcPlc: ContratMenuConviveDecoupage__PlcDTO;

  cmcPlanningSupplier: CmcPlanningSupplier = new CmcPlanningSupplier();

  rows: ContratMenuConviveRepasDTO[] = [];
  cols: DecoupageRepasDTO[] = [];
  selectedPeriode: ContratMenuConvive__ModeleNutriDTO;
  cmcdList: ContratMenuConviveDecoupageDTO[] = [];
  joursSemaine: JourSemaine[] = [];
  repasList: RepasDTO[] = [];
  regimeList: RegimeAlimentaireDTO[] = [];
  maxPlats: number;
}


