<dx-popup
  [showTitle]="true"
  [title]="'Modifier les coûts prévisionnels'"
  [(visible)]="displayCoutDialog"
  [width]="800"
  [height]="'auto'"
  [maxHeight]="700"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  [style]="{'overflow':'visible'}"
  (onHidden)="closeDialog()"
>
  <div class="row">
    <div class="col-md-4">
      <div class="dx-field mt-3">
        <h3 class="first">Jours ouvrés</h3>
        <yo-tree-list
          #treeListJoursSemaineCout
          [data]="joursSemaine"
          [returnData]="true"
          key="value"
          displayValue="viewValue"
          mode="list"
          (onValueChange)="onChangeJoursSemaine($event)">
          <dxo-selection mode="multiple" [recursive]="true"></dxo-selection>
          <dxi-column dataField="libelle" caption="Tout sélectionner"></dxi-column>
        </yo-tree-list>
      </div>
    </div>

    <div class="col-md-4">
      <div class="dx-field mt-3">
        <h3 class="first">Repas et régimes</h3>
        <yo-tree-list
          #treeListRepasListCout
          [data]="repas"
          [returnData]="true"
          key="id"
          displayValue="libelle"
          childValue="children"
          mode="groupedList"
          (onValueChange)="onChangeParentDenreeSelection($event)">
          <dxo-selection mode="multiple" [recursive]="true"></dxo-selection>
          <dxi-column dataField="libelle" caption="Tout sélectionner"></dxi-column>
        </yo-tree-list>
      </div>
    </div>

    <div class="col-md-4">
      <div class="dx-field mt-3">
        <h3 class="first">Coût Prévisionnel</h3>
        <div class="input-group input-group-sm ">
          <input type="text" style="width: 60px;" [(ngModel)]="majCoutPrev">
          <div class="input-group-append ">
            <span class="input-group-text">€</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 text-right">
      <yo-button iconClass="fas fa-check" class="mg-r-5"
                 (onClick)="save(selectedJoursSemaine, selectedRepasList, selectedRegimeList, majCoutPrev)"></yo-button>
    </div>
  </div>

</dx-popup>
