import {Component, Input, OnInit} from '@angular/core';
import {DATEPICKER_FR, USER_PREFERENCE} from '../../../../core/constants';
import {PreferencesUtilisateurService} from '../../../../core/services/preferences-utilisateur.service';
import {UtilsService} from '../../../../core/utils/utils.service';
import {forEach as _forEach} from 'lodash';
import {ContratMenuConvive__ContrainteAlimDTO} from '../../../../core/dtos/contratmenuconvive__contrainte-alim-dto';
import {SelectItem} from 'primeng/api';
import {PrintMenuService} from '../../../../core/services/gestionmenus/print-menu.service';
import {RepasDTO} from '../../../../core/dtos/repas-dto';
import {ResponseWrapper} from '../../../../core/suppliers/wrappers/response-wrapper';
import {PrintCoutMatiereDTO} from '../../../../core/dtos/gestionmenus/print-cout-matiere/print-cout-matiere-dto';
import {PrintCoutMatiereService} from '../../../../core/services/gestionmenus/print-cout-matiere.service';
import {ContratMenuConviveDTO} from '../../../../core/dtos/contratmenuconvive-dto';

@Component({
  selector: 'yo-print-cout-matiere',
  templateUrl: './print-cout-matiere.component.html',
  styleUrls: ['./print-cout-matiere.component.scss']
})
export class DialogPrintCoutMatiereComponent implements OnInit{

  @Input() public contratsMenuConvive: ContratMenuConviveDTO;
  @Input() public selectedStartDateMenus: Date;
  @Input() public selectedRegime: ContratMenuConvive__ContrainteAlimDTO;
  @Input() public regimeList:ContratMenuConvive__ContrainteAlimDTO[];
  @Input() public repasList:RepasDTO[];

  startDate: Date;
  minDate: Date;
  endDate: Date;
  fr = DATEPICKER_FR;
  selectedRegimeId:number;
  regimeOptionList: SelectItem[] = [];
  repasOptionlist: SelectItem[] = [];
  repasListResult: any[] =[];
  displayDialog: boolean = false;

  constructor(
    private preferencesUtilisateurService : PreferencesUtilisateurService,
    private printMenuService: PrintMenuService,
    private printCoutMatiereService: PrintCoutMatiereService,
    public utils: UtilsService) {}


  ngOnInit(){
    this.printCoutMatiereService.displayDialogCoutMatiere$.subscribe((response: boolean) =>{
      this.displayDialog = response;
      this.initRegimeOptionList();
      this.startDate = this.selectedStartDateMenus;
      this.minDate = new Date(this.startDate.getTime());
      this.endDate = this.getEndDate(this.startDate);
      this.repasOptionlist =  this.getRepasOptionlist(this.repasList);
    });
  }

  getRepasOptionlist = (repasList :RepasDTO[]) => {
    let repasOptionList:  SelectItem[] =[];
    if(repasList){
      repasList.forEach( (repas: RepasDTO) =>{
        repasOptionList.push(<SelectItem>{label:repas.libelle, value:repas.id});

        //on pré-selectionne tous les menus a l'init du dialog
        this.repasListResult.push(repas.id);
      });
    }
    return repasOptionList;
  };


  getEndDate = (startDate: Date): Date => {
    let endDate : Date;
    const offset: number = this.preferencesUtilisateurService.getPreferenceUtilisateurArrIntValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_NBJOURS)[0];
    endDate =  new Date(startDate);
    endDate.setDate(endDate.getDate() + offset);
    return endDate;
  };

  printCoutMatiere = () => {

    this.printMenuService.printCoutMatiere(this.startDate, this.endDate, this.contratsMenuConvive.id, this.selectedRegimeId, this.repasListResult)
      .subscribe((response : ResponseWrapper<PrintCoutMatiereDTO>) =>{

        this.printCoutMatiereService.getJsonForExcel(response.one, response.additionalProperties, this.startDate, this.endDate, this.repasList);

        this.displayDialog= false;

    });
  };

  initRegimeOptionList = () => {
    _forEach(this.regimeList, (item: ContratMenuConvive__ContrainteAlimDTO)  => {
      if(this.utils.isCollectionNullOrEmpty(this.regimeOptionList) || this.regimeOptionList.find(r => r.value != item.regimeId))
        this.regimeOptionList.push({label: item.libelle, value: item.id})
    });
    this.selectedRegimeId = this.selectedRegime.id;
  };

  isValidForm = () => {
    const isStartDateValid : boolean =  !this.utils.isNullOrEmpty(this.startDate);
    const isEndDateValid : boolean =  !this.utils.isNullOrEmpty(this.endDate);
    return !(isStartDateValid && isEndDateValid && this.selectedRegimeId);
  };

  closeDialog = () => {
    this.displayDialog = false;
  };

  startDateChanged = ($event: any) => {
    this.startDate = $event.value;
    this.minDate = new Date(this.startDate.getTime());
    if(this.minDate > this.endDate) this.endDate = null;
  }

  endDateChanged = ($event: any) => this.endDate = $event.value;

  regimeChanged = ($event: any) => this.selectedRegimeId = $event.value;

  repasChanged = ($event: any) => this.repasListResult = $event.value;
}
