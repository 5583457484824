import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {RepasDTO} from "../../../../../core/dtos/repas-dto";
import {JourSemaine} from "../../../../../core/constants";
import {RegimeAlimentaireDTO} from "../../../../../core/dtos/regime-alimentaire-dto";
import {TreeListComponent} from "../../../../../shared/ui/tree-list/tree-list.component";

@Component({
  selector: 'yo-dialog-update-couts',
  templateUrl: './dialog-update-couts.component.html',
  styleUrls: ['./dialog-update-couts.component.scss']
})
export class DialogUpdateCoutsComponent implements OnInit {

  @Output() onClose = new EventEmitter<boolean>();
  @Output() onSave = new EventEmitter<any>();

  @Input() displayCoutDialog: boolean;
  @Input() repasList: RepasDTO[];
  @Input() joursSemaine: JourSemaine[] = [];

  @ViewChild("treeListJoursSemaineCout", {static: false}) treeListJoursSemaineCout: TreeListComponent;
  @ViewChild("treeListRepasListCout", {static: false}) treeListRepasListCout: TreeListComponent;

  repas: any[] = [];

  majCoutPrev: number;
  selectedJoursSemaine: JourSemaine[] = [];
  selectedRepasList: RepasDTO[] = [];
  selectedRegimeList: RegimeAlimentaireDTO[] = [];

  constructor() {
  }

  ngOnInit(): void {
    this.initData();
  }

  initData = (): void => {
    this.repas = this.repasList.map(item => ({
      "libelle": item.libelle,
      "children": item.regimesAlimentaire.map(regime => ({
        id: regime.id,
        libelle: regime.libelle,
        parentId: item.id
      }))
    }));
  }

  closeDialog: () => void = async () => {
    this.selectedJoursSemaine = [];
    this.selectedRegimeList = [];
    this.selectedRepasList = [];
    this.majCoutPrev = null;
    this.treeListJoursSemaineCout.clearSelection();
    this.treeListRepasListCout.clearSelection();
    this.displayCoutDialog = false;
    this.onClose.emit(this.displayCoutDialog);
  }

  onChangeJoursSemaine = ($event: any) => this.selectedJoursSemaine = $event;

  onChangeParentDenreeSelection = ($event: any) => {
    $event.forEach(row => {
      if (!this.selectedRegimeList.find(item => item.id === row.id)) {
        this.selectedRegimeList.push(row);
        if (!this.selectedRepasList.find(item => item.id === row.parentId))
          this.selectedRepasList.push(this.repasList.find(item => item.id === row.parentId));
      }
    });
  };

  save = (selectedJoursSemaine: JourSemaine[], selectedRepasList: RepasDTO[], selectedRegimeList: RegimeAlimentaireDTO[], majCoutPrev: number) => {
    this.onSave.emit({selectedJoursSemaine, selectedRepasList, selectedRegimeList, majCoutPrev});
    this.closeDialog();
  };
}
