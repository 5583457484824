import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppellationsService} from '../../core/services/entities/appellations.service';
import {UtilsService} from '../../core/utils/utils.service';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../core/constants";
import {Subscription} from "rxjs";
import {saveAs} from "file-saver";
import {confirm} from "devextreme/ui/dialog";
import {ResponseWrapper} from "../../core/suppliers/wrappers/response-wrapper";
import {IsDeletableObject} from "../../core/models/is-deletable-object";
import {ImageSize} from "../../core/enums/image-size-enum";
import {AppellationDTO} from "../../core/dtos/appellations-dto";
import {InternationalizationService} from "../../core/services/i8n/i8n.service";
import {ToastService} from "../../core/services/technique/toast.service";
import {Auth2Service} from "../../core/services/security/auth2.service";
import {GraphQLService} from "../../core/services/technique/graphql.service";
import {DxTooltipComponent} from "devextreme-angular";

@Component({
  selector: 'yo-appellations',
  templateUrl: './appellations.component.html',
  styleUrls: ['./appellations.component.scss']
})
export class AppellationsComponent implements OnInit, OnDestroy {

  @ViewChild("importTooltip") importTooltip: DxTooltipComponent;

  subLog: Subscription;
  subAppellationSaved: Subscription;
  subAppellations: Subscription;
  importSub: Subscription;
  exportSub: Subscription;
  deleteSub: Subscription;

  logged: boolean = false;

  appellations: AppellationDTO[] = [];

  selectedRows: number[] = [];
  errors: any[] = [];

  displayImagePopup: boolean = false;
  displayImportPopupErrors: boolean = false;
  displayDeletePopupErrors: boolean = false;
  imageTitle: string = "Logo"
  imagePath: string = "";

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/appellations/appellations';

  constructor(public readonly appellationsService: AppellationsService,
              public readonly utils:UtilsService,
              private readonly toastSvc: ToastService,
              private readonly i8nSvc: InternationalizationService,
              private readonly auth2Svc: Auth2Service,
              private readonly graphQlSvc: GraphQLService) {
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subLog);
    this.utils.unsubscribe(this.subAppellationSaved);
    this.utils.unsubscribe(this.subAppellations);
    this.utils.unsubscribe(this.importSub);
    this.utils.unsubscribe(this.exportSub);
    this.utils.unsubscribe(this.deleteSub);
  }

  ngOnInit(): void {
    this.initAppellationsSubscription();
    this.initAppellationsSavedSubscription();
  }

  openDialogEdition = (app?: AppellationDTO): void => {
    this.appellationsService.announceOpenDialogEdition(app);
  };

  calculateSiteValue = (appellation: AppellationDTO): String => {
    return appellation?.site?.libelle
  };

  initAppellationsSubscription = (): void => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    this.subAppellations = this.graphQlSvc.sendQuery(`
      {
        allAppellations(filters: {
          siteIds: [${idsSites}]
        }) {
            id,
            code,
            libelle,
            actif,
            durable,
            bio,
            ordre,
            site {
                id,
                libelle,
            },
        }
      }
    `)
      .subscribe((response: any) => {
        this.appellations = response.allAppellations;
      });
  }

  initAppellationsSavedSubscription = (): void => {
    this.subAppellationSaved = this.appellationsService.appellationSaved$
      .subscribe(() => {
        const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
        this.graphQlSvc.sendQuery(`
          {
            allAppellations(filters: {
              siteIds: [${idsSites}]
            }) {
                id,
                code,
                libelle,
                actif,
                durable,
                bio,
                site {
                    id,
                    libelle,
                },
            }
          }
        `)
          .toPromise()
          .then((response: any) => {
            this.appellations = response.allAppellations;
          });
      });
  };

  exportXslx = () => {
    this.exportSub = this.appellationsService.exportByFilters(this.selectedRows).subscribe(response => {
      const filename = `appellation-export-${new Date().getTime()}.xlsx`;
      const blob = new Blob([response], {type: 'application/vnd.ms-excel'});
      this.selectedRows = [];
      saveAs(blob, filename);
    });
  }

  importXslx = async ($event: any) => {
    this.errors = [];
    if ($event.target.files && $event.target.files.length) {
      const file: File = $event.target.files[0];
      this.importSub = this.appellationsService.importFromXlsx(file)
        .subscribe(response => {
          const res: any = response.one;
          if (res.allElementsImported)
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Le fichier a été importé avec succès`);
          else {
            for (const item of Object.entries(res.reports)) {
              let values: any[] = Array.of(item[1]);
              const labelsError: any[] = values.map(val => val.map(v => this.i8nSvc.getLabelFromCode(v.code, v.args.split(','))).join(', '));
              this.errors.push({infosLine: item[0], labelsError});
            }
          }
          for (const item of res.elementsImported) {
            let index = this.appellations.findIndex(appellation => appellation.id == item.id);
            if (index >= 0)
              this.appellations[index] = item;
            else
              this.appellations.push(item)
          }

          if (this.errors != null && this.errors.length > 0)
            this.displayImportErrors();

          $event.target.value = "";
          this.importTooltip.instance.hide();
        });
    }
  }

  displayImportErrors = (): void => {
    this.displayImportPopupErrors = true;
  }

  displayDeleteErrors = (): void => {
    this.displayDeletePopupErrors = true;
  }

  closeErrors = (): void => {
    this.errors = [];
    this.displayImportPopupErrors = false;
    this.displayDeletePopupErrors = false;
  }


  deleteValues = async (): Promise<void> => {
    this.errors = [];
    let idsToDeleteInTable = Object.assign([], this.selectedRows);

    let isDeletionValidated: boolean = await confirm(this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? "CONFIRM_DELETION_PLURAL" : "CONFIRM_DELETION_SINGULAR", null), this.i8nSvc.getLabelFromCode("Suppression", null));

    if (!isDeletionValidated)
      return new Promise(null);

    this.deleteSub = this.appellationsService.deleteByFilters(this.selectedRows).subscribe(response => {
      const res: any = (response as ResponseWrapper<IsDeletableObject>).one;

      if (res) {
        if (res.messagesErrorList?.length) {
          for (const error of res.messagesErrorList) {
            const infosLine: string = this.i8nSvc.getLabelFromCode(error.code, error.args.split(','));
            this.errors.push({infosLine});
            const elementToKeep = this.appellations.find(appellation => appellation.code === error.args);
            idsToDeleteInTable = idsToDeleteInTable.filter(id => elementToKeep.id !== id);
          }
          this.displayDeleteErrors();
        } else {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? `APPELLATION_DELETION_COMPLETED_PLURAL` : `APPELLATION_DELETION_COMPLETED_SINGULAR`, null));
        }
        this.appellations = this.appellations.filter(appellation => !idsToDeleteInTable.find(id => id === appellation.id));
      }
    });
  }

  canDelete = (): boolean => this.selectedRows.length > 0;

  getLogoUrl  = (item: AppellationDTO): string => {
    return this.appellationsService.getUrl(item, ImageSize.Thumb);
  }

  openImagePopup = (item: AppellationDTO) => {
    this.displayImagePopup = true;
    this.imageTitle = item.libelle;
    this.imagePath = this.appellationsService.getUrl(item, ImageSize.Full, true)
  };

  closeImagePopup = () => {
    this.imagePath = "";
    this.imageTitle = ""
    this.displayImagePopup = false;
  }

}
