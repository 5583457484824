import {FamilleNutritionnelleProduitDTO} from './famillenutritionelle-produit-dto';
import {ProduitAllergeneDTO} from './produit-allergene-dto';
import {ProduitAppellationDTO} from './produit-appellation-dto';
import {ObjectDTO} from './object-dto';
import {DeclinaisonDTO} from './declinaison-dto';
import {UniteDeMesureDTO} from './unitedemesure-dto';
import {TaxeDTO} from './taxe-dto';
import {ProduitApportNutritionnelDTO} from './produit-apportnutritionnel-dto';
import {ZoneDeStockageDTO} from './zone-de-stockage-dto';
import {ProduitDeclinaisonContrainteAlimDTO} from './produit-declinaison-contrainte-alim-dto';
import {ProduitDeclinaison__TacheDTO} from './produit-declinaison-tache-dto';
import {ProduitnomenclatureDTO} from "./produitnomenclature-dto";
import {AppellationDTO} from "./appellations-dto";
import {AllergeneDTO} from "./allergene-dto";
import {FamilleProduitDTO} from "./famille-produit-dto";
import {ProduitDTO} from "./produit-dto";
import {OrigineCarneDTO} from "./origine-carne-dto";

export class ProduitDeclinaisonDTO extends ObjectDTO {


  libelle: string;
  libellelong: string;
  code: string;
  reference: string;
  codecomptable: string;
  guid: string;
  dlc: number;

  ratioUniteTechnique: number;
  ratioVolume: number;
  prixAchat: number;
  prixDeVente: number;
  declinaison: DeclinaisonDTO;
  actif: boolean;
  uniteDeMesure: UniteDeMesureDTO;
  taxe: TaxeDTO;

  // Subtitution
  isSubtitution: boolean;
  pdOriginalLibelle: string;
  pdOriginalId : number;

  // TYPE PRODUIT, récupéré de produit
  fabrique: boolean;
  typeProduitId: number;
  typeProduitLibelle: string;

  //Catalogue Achat UDP
  udpPrixAchat:number;

  // FAMILLE PRODUIT, récupéré de produit
  familleProduitLibelle: string;
  familleProduitId: number;
  familleProduit: FamilleProduitDTO;

  // DO NOT MAP PRODUITD TO TO AVOID CIRCULAR DEPENDENCY
  produit: ProduitDTO;
  produitSiteId: number;
  produitSiteLibelle: string;
  produitActif: boolean;
  produitReference: string;
  produitCode: string;
  produitId: number;
  produitLibelle: string;
  produitGuid: string;
  produitLibelleLong: string;

  famillesNutritionnellesProduits: FamilleNutritionnelleProduitDTO[] = [];
  produitAllergeneList: ProduitAllergeneDTO[] = [];
  allergenes: AllergeneDTO[] = [];
  produitAppellationList: ProduitAppellationDTO[];
  appellations: AppellationDTO[] = [];
  produitApportNutritionnelList: ProduitApportNutritionnelDTO[] = [];
  produitDeclinaisonContrainteAlimList:ProduitDeclinaisonContrainteAlimDTO[] = [];
  produitDeclinaison__tacheList: ProduitDeclinaison__TacheDTO[];
  dishesWichUsedCurrentFoodStuff: ProduitnomenclatureDTO[];

  zoneDeStockage:ZoneDeStockageDTO;

  hasArticles: boolean;

  modeOperatoire: string;

  privilegie: boolean;

  erreurPrix: boolean;
  messageErreurPrix: string;

  totalPoidsNetRecetteUT: number;

  //variable pour gérer l'ajout des quantités au niveau de l'ajout d'une denrée pour une commande fournisseur
  quantite: number;

  origine: OrigineCarneDTO;

  sansAppro: boolean;

  familleEgalimId: number;
}
