import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from "rxjs/index";
import {ActivatedRoute} from "@angular/router";
import {PreparationConditionnementSupplier} from "../../preparation-conditionnement-resolver.service";
import {
  PreparationConditionnementService
} from "../../../core/services/conditionnements/preparation-conditionnement.service";
import {UtilsService} from "../../../core/utils/utils.service";
import {DATAGRID_ROW_TYPES} from "../../../core/services/technique/devextreme.service";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY, UI_COLORS} from "../../../core/constants";
import {PointDeLivraisonDTO} from "../../../core/dtos/point-de-livraison-d-t-o";
import {RoutemapService} from "../../../core/services/routemap.service";
import {PrintMenuService} from "../../../core/services/gestionmenus/print-menu.service";
import {LigneConditionnementDetailsDto} from "../../../core/dtos/conditionnement/ligne-conditionnement-details-dto";
import {InternationalizationService} from "../../../core/services/i8n/i8n.service";
import {join} from "@angular/compiler-cli/src/ngtsc/file_system";
import {DxDataGridComponent, DxDataGridModule} from "devextreme-angular";

@Component({
  selector: 'yo-preparation-calcul-conditionnement',
  templateUrl: './preparation-calcul-conditionnement.component.html',
  styleUrls: ['./preparation-calcul-conditionnement.component.scss']
})
export class PreparationCalculConditionnementComponent implements OnInit, OnDestroy {

  data: LigneConditionnementDetailsDto[];
  subscriptionRoute: Subscription;
  displayErrors: boolean;
  loadingVisible: boolean;
  idPlanProduction: number;
  errors: any = [];
  NoDataText: string;
  idParentSelected: number;
  expandAll: boolean = false;
  plcsNotTakenAccount: PointDeLivraisonDTO[] = [];

  pathFile: string = HELP_FOLDERS.CONDITIONNEMENT + '/conditionnement-calcul-global';

  planProduction: string;

  constructor(private route: ActivatedRoute,
              private routeMapSvc: RoutemapService,
              private pcSvc: PreparationConditionnementService,
              public utils: UtilsService,
              public printMenuSvc: PrintMenuService,
              private i8nSvc: InternationalizationService) {
  }

  ngOnInit(): void {
    this.routeSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
  }

  routeSubscription = () => {
    this.subscriptionRoute = this.route.parent.data
      .subscribe((data: { preparationConditionnementSupplier: PreparationConditionnementSupplier }) => {
        if (data.preparationConditionnementSupplier) {
          this.idPlanProduction = data.preparationConditionnementSupplier.idPlanProduction;
          this.startCalculation(data.preparationConditionnementSupplier.isRefresh);
        }
      });
  };

  goToProductPage = (idProduct: number) => window.open(`/gestionproduits(secondary:gestionproduits/produit/true/${idProduct}/conditionnement)`, '_blank');

  goToPlcPage = (idClient: number, idPlc: number) => window.open(`/gestion-clients(secondary:point-de-livraison/${idClient}/${idPlc}/conditionnement/regles-regroupements)`, '_blank');

  closeErrors = () => {
    this.displayErrors = false;
  }

  startCalculation = async (refresh?: boolean) => {
    this.NoDataText = 'En cours de chargement...';
    this.loadingVisible = true;
    this.pcSvc.startCalculation(this.idPlanProduction, refresh)
      .subscribe(data => {
          const res: any = data;
          if (res.one?.reports) {
            for (const [key, value] of Object.entries(res.one.reports)) {
              const v: any = value;
              const argsArray = v.args ? v.args.split(",") : [];
              const error: any = this.i8nSvc.getLabelFromCode(v.code, argsArray);
              this.errors.push({error: error});
              this.displayErrors = true;
            }
          }
          this.pcSvc.getAllResults(this.idPlanProduction)
            .subscribe(data => {
              this.data = data.one.results;
              this.planProduction = data.one.planProduction;
            });
          this.loadingVisible = false;
        }
      );
  };

  previousStep = () => {
    this.routeMapSvc.goToSecondaryRoute(['steps', 'plan-production', this.idPlanProduction]);
  }

  printEditionConditionnement = (): void => {
    this.pcSvc.announcePrintConditionnement();
  }

  changeCollapseAll = () => {
    this.expandAll = !this.expandAll;
  }
}
