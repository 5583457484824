import {Component, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {FormControl, FormGroup} from "@angular/forms";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {ToastService} from "../../../core/services/technique/toast.service";
import {UtilsService} from "../../../core/utils/utils.service";
import {SiteDTO} from "../../../core/dtos/site-dto";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import {AppellationsService} from "../../../core/services/entities/appellations.service";
import {ImageSize} from "../../../core/enums/image-size-enum";
import {AppellationDTO} from "../../../core/dtos/appellations-dto";

@Component({
  selector: 'yo-appellationEditionDialog',
  templateUrl: './appellation-edition-dialog.component.html',
  styleUrls: ['./appellation-edition-dialog.component.scss']
})
export class AppellationEditionDialogComponent implements OnInit {

  subOpenDialog: Subscription

  appellation: AppellationDTO;

  form: FormGroup = new FormGroup({});

  dialogTitle: string = 'Modification d\'une appellation';

  sitePlaceholder: string = 'Selectionner le site...';

  displayDialog = false;

  idSelectedSite: number;

  selectedFile: File;

  selectedFileUrl: string;

  currentFileUrl: string;

  displayNewImage: boolean;

  fileInput: HTMLInputElement;

  isDropZoneActive: boolean;

  dropZone: HTMLElement;

  dragCounter: number = 0;

  sendDeleteCurrentImageRequest: boolean;

  animatedContent: HTMLDivElement;

  isCurrentImageInterractable: boolean;

  needToDeleteCurrentImage: boolean;

  constructor(private readonly auth2Svc: Auth2Service,
              private readonly toastSvc: ToastService,
              public readonly appellationsSvc: AppellationsService,
              public readonly utilsSvc: UtilsService) {
  }

  ngOnInit() {
    this.initDragAndDrop();
    this.initForm();
    this.openDialogEditionSubscription();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subOpenDialog);
  }

  initDragAndDrop = () => {
    this.dropZone = document.getElementById("dropzone");
    this.fileInput = document.getElementById("fileInput") as HTMLInputElement;
    this.animatedContent = document.getElementById("animatedContent") as HTMLDivElement;
    this.animatedContent.addEventListener("transitionrun", this.displayAnimatedContent);
    this.animatedContent.addEventListener("transitionstart", this.displayAnimatedContent);
    this.animatedContent.addEventListener("transitioncancel", this.displayAnimatedContent);
    this.animatedContent.addEventListener("transitionend", this.displayAnimatedContent);

    this.dropZone.addEventListener("dragenter", this.toggleDragZone);
    this.dropZone.addEventListener("dragover", this.toggleDragZone);
    this.dropZone.addEventListener("dragleave", this.toggleDragZone);
    this.dropZone.addEventListener("drop", this.drop);
  }

  toggleDragZone = (e) => {
    e.preventDefault();
    if (e.type === "dragenter")
      this.dragCounter += 1;
    else if (e.type === "dragleave")
      this.dragCounter -= 1;

    this.isDropZoneActive = this.dragCounter != 0;
  }

  displayAnimatedContent = (event: TransitionEvent) => {
    console.log("trigger : " + event.type);
    if ((event.type === "transitionend" || event.type === "transitioncancel") && this.animatedContent.classList.contains("hide"))
      this.animatedContent.classList.add("hidden");
    else if ((event.type === "transitionrun" || event.type === "transitionstart") && this.animatedContent.classList.contains("hidden"))
      this.animatedContent.classList.remove("hidden");
  }

  drop = (e) => {
    e.preventDefault();
    this.dragCounter = 0;
    this.isDropZoneActive = false;
    e.dataTransfer
    this.onImageSelected({
      target: {
        files: e.dataTransfer.files
      }
    });
  }

  openDialogEditionSubscription = (): void => {
    this.subOpenDialog = this.appellationsSvc.openDialog$
      .subscribe((a: AppellationDTO) => {
        this.displayDialog = true;
        if (!a) {
          this.appellation = new AppellationDTO();
          this.appellation.id = 0;
          this.dialogTitle = 'Création d\'une appellation';
        } else {
          this.appellation = a;
          this.dialogTitle = 'Modification d\'une appellation';
        }
        this.initForm();
      });
  };

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  closeDialog = (): void => {
    this.displayDialog = false;
    this.displayNewImage = false;
    this.currentFileUrl = null;
  };

  save = (): void => {
    if (this.form.valid) {
      let newAppellation: AppellationDTO = {...this.appellation};
      newAppellation.site = {id: this.idSelectedSite} as SiteDTO;
      newAppellation.libelle = this.form.controls['libelle'].value;
      newAppellation.actif = this.form.controls['actif'].value;
      newAppellation.bio = this.form.controls['bio'].value;
      newAppellation.durable = this.form.controls['durable'].value;
      newAppellation.ordre = this.form.controls['ordre'].value;
      this.appellationsSvc.save(newAppellation, this.needToDeleteCurrentImage, this.selectedFile)
        .subscribe(() => {
          this.appellationsSvc.announceAppellationSaved();
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Sauvegarde de l'appellation réalisée avec succès`);
          this.closeDialog();
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  };

  initForm = (): void => {
    this.displayNewImage = false;
    this.needToDeleteCurrentImage = false;
    this.resetSelectedImage();
    this.sitePlaceholder = !this.canModify() ? this.appellation?.site?.libelle : 'Selectionner le site...';
    this.idSelectedSite = this.appellation?.site?.id;
    this.currentFileUrl = this.appellation?.id != null && this.appellation.id > 0 ? this.appellationsSvc.getUrl(this.appellation, ImageSize.Full, true) : this.appellationsSvc.noImagePath;
    this.selectedFileUrl = null;
    this.form = new FormGroup({
      libelle: new FormControl(this.appellation?.libelle),
      actif: new FormControl(this.appellation?.actif != null ? this.appellation.actif : true),
      bio: new FormControl(this.appellation?.bio != null ? this.appellation.bio : true),
      durable: new FormControl(this.appellation?.durable != null ? this.appellation.durable : true),
      ordre: new FormControl(this.appellation?.ordre)
    });
  };

  onChangeSite = ($event: any): void => {
    this.idSelectedSite = $event.selectedItem?.id;
  }

  canModify = () => {
    return this.appellationsSvc.canModify(this.appellation);
  }

  onImageSelected = (event: any) => {
    this.needToDeleteCurrentImage = false;
    this.selectedFile = event?.target?.files[0];
    this.selectedFileUrl = null;

    this.displayNewImage = this.selectedFile != null;

    if (this.displayNewImage) {
      const reader = new FileReader();

      reader.onload = (_event: any) => {
        this.selectedFileUrl = _event.target.result;
      };
      reader.readAsDataURL(this.selectedFile);
    }
  }

  deleteCurrentImage = () => {
    this.resetSelectedImage();
    this.needToDeleteCurrentImage = true;
    this.displayNewImage = true;
    this.sendDeleteCurrentImageRequest = true;
  }

  cancelSelectedImage = () => {
    this.resetSelectedImage();
  }

  resetSelectedImage = () => {
    if (this.fileInput)
      this.fileInput.value = null;
    this.onImageSelected(null);
  }

  onCurrentImageLoaded = (event: Event) => {
    this.isCurrentImageInterractable = !(event.target as HTMLImageElement).src.endsWith(this.appellationsSvc.noImagePath);
  }

}
