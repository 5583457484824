<dx-popup
  [showTitle]="true"
  [title]="dialogTitle"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [showCloseButton]="true"
  [fullScreen]="true"
  (onHiding)="onClosingByCrossIcon($event)"
  [(visible)]="displayDialog"
>

  <div class="d-flex flex-column" *ngIf="_bonCf">

    <div class="d-flex flex-row">
      <div class="d-flex flex-column mg-r-20 panel-fournisseur">

        <label title="Fournisseur">
          <i class="fas fa-truck mg-r-5"></i>{{_bonCf?.fournisseurLibelle | titlecase}}
        </label>

        <label title="Unité de production">
          <i class="fas fa-map-marker mg-r-5"></i>
          {{_bonCf?.uniteDeProductionLibelle | titlecase}}
        </label>

        <label title="Code Client">
          Code Client : {{udpSecteurFournisseur?.codeClient| titlecase}}
        </label>

        <label title="Date de livraison prévue">
          <i class="fas fa-calendar mg-r-5"></i>Livraison prévue le
          <p-calendar title="Date de livraison ajustée"
                      firstDayOfWeek="1"
                      [locale]="fr"
                      [showIcon]="true"
                      [(ngModel)]="dateLivCalendar"
                      (ngModelChange)="onDateLivUpdated()"
                      [ngModelOptions]="{standalone: true}"
                      dateFormat="dd/mm/yy"
                      readonlyInput="true"
                      [showWeek]="true"
                      [minDate]="minDate">
            <ng-template pTemplate="date" let-date>
              <ng-container
                *ngTemplateOutlet="tplDateMenuCompo;context:{isDateFournisseur:bonCfSvc.isDateFournisseur(date,joursSemaineFournisseur,udpJoursLivraison,delaiLivraison,heureLimite),date:date}"></ng-container>
            </ng-template>
          </p-calendar>
        </label>
      </div>
      <div class="d-flex flex-column">
        <div>
          <label> Remarques &nbsp;</label><label [style]="getStyleRemarque()"> ({{getNbCaracteresCommentaire()}}/5000
          caractère(s)) &nbsp;: &nbsp; </label>
          <p-editor (onTextChange)="checkEditionMode()" [(ngModel)]="_bonCf.commentaire" [style]="{'height':'60px'}"
                    maxlength="5000">
            <ng-template pTemplate="header">
              <yo-primeng-editor-header></yo-primeng-editor-header>
            </ng-template>
          </p-editor>
        </div>
      </div>
    </div>
    <div class="border-header">
      <div style="text-align: left;">
        <ng-container *ngIf="auth2Svc.isSiteLocal(_bonCf.site.id)">
                    <span>
                      <yo-button label="Ajouter des articles" iconClass="fas fa-plus"
                                 (onClick)="showDialogAddArticles()"></yo-button>
                      🗣️<em> N'oubliez pas d'enregistrer votre travail.</em>
                    </span>
          <span class="space cursor  mg-r-5 floatright">
                    <yo-button
                      iconClass="fas fa-trash"
                      buttonClass="cta-inner-delete"
                      (onClick)="removeLignesCommande()"
                      [disabled]="utils.isCollectionNullOrEmpty(selectedRows)"
                      tooltip="Supprimer les articles sélectionnés de la proposition de commande... Il ne seront réellement supprimés que lorsque vous enregistrerez.">
                  </yo-button>
                    </span>
        </ng-container>
      </div>
    </div>
    <div>
      <dx-data-grid
        [dataSource]="_lignesBonCf"
        [height]="utils.getWindowAvailableHeight(415)"
        keyExpr="idCatalogueAchat"
        width="100%"
        [allowColumnResizing]="true"
        [hoverStateEnabled]="true"
        [rowAlternationEnabled]="true"
        [showRowLines]="true"
        [showBorders]="true"
        (onEditorPreparing)="onEditorPreparing($event)"
        [(selectedRowKeys)]="selectedRows"
        #grid>
        <dxo-editing mode="cell" [allowUpdating]="true" [allowAdding]="false" [allowDeleting]="false"></dxo-editing>
        <dxo-selection [selectAllMode]="allMode" [showCheckBoxesMode]="checkBoxesMode" mode="multiple"></dxo-selection>

        <dxi-column dataField="referenceArticle" cellTemplate="templateReferenceArticle" caption="Réf. article"
                    [allowEditing]="false" [width]="100"></dxi-column>
        <dxi-column dataField="description" cellTemplate="templateDescription" caption="Description"
                    [allowEditing]="false" [width]="300"></dxi-column>
        <dxi-column dataField="prixUnitaire" cellTemplate="templatePrixUnitaire" caption="PU UF"
                    [setCellValue]="changePrixUnitaire"></dxi-column>
        <dxi-column dataField="quantiteAFacturer" caption="Qté Fact. UF" cellTemplate="templateQteAFacturer"
                    [allowEditing]="false"></dxi-column>
        <dxi-column dataField="quantiteACommander" caption="Qté Com. UC" cellTemplate="templateQteACommander"
                    [allowEditing]="false"></dxi-column>
        <dxi-column dataField="quantiteACommanderAjustee" caption="Qté Com. Aju. UC" cellTemplate="templateQteAjustee"
                    [setCellValue]="changeQteAjustee"></dxi-column>
        <dxi-column dataField="prixTotal" caption="Prix Total" cellTemplate="templatePrixTotal"
                    [allowEditing]="false"></dxi-column>
        <dxi-column dataField="quantiteBesoin" caption="Besoin UT" cellTemplate="templateBesoinUT"
                    [allowEditing]="false"></dxi-column>
        <dxi-column dataField="quantiteStock" caption="Qté Stock" cellTemplate="templateStock"
                    [allowEditing]="false"></dxi-column>
        <dxi-column dataField="commentaire" caption="Remarque" cellTemplate="templateRemarque"
                    [setCellValue]="changeRemarque"></dxi-column>

        <div *dxTemplate="let cell of 'templateTva'" class="text-left">
          {{ tva }}
        </div>
        <div *dxTemplate="let cell of 'templateReferenceArticle'" class="text-left">
          <span class="space">{{ cell.row.data.referenceArticle }}</span>
          <span *ngIf="cell.row.data.id===0"><label class="badge badge-success">Ajouté manuellement</label></span>
        </div>
        <div *dxTemplate="let cell of 'templateDescription'" class="text-left" style="white-space: pre-wrap;">
          {{ cell.row.data.description }}
          <br/>
          <yo-info-lotmarche-article [uniteDeProductionId]="_bonCf?.uniteDeProductionId"
                                     [lotMarchePdLumpCa]="cell.row.data.lmPdUpCa"></yo-info-lotmarche-article>
        </div>
        <div *dxTemplate="let cell of 'templatePrixUnitaire'" class="save-color cursor text-left">
          <span class="space">
            {{ cell.row.data.prixUnitaire | currency:'EUR':'symbol':'1.4-4'}}
            / {{cell.row.data.uniteDeFacturation.abreviation | titlecase}}
          </span>
          <span *ngIf="cell.row.data.prixUnitaire < 0">
            <i class="fas fa-exclamation-triangle danger-color space" title="Le prix doit être supérieur à 0"></i>
          </span>
        </div>
        <div *dxTemplate="let cell of 'templateQteAFacturer'" class="text-left"
             title="Quantité à facturer, 1 {{cell.row.data.uniteDeFacturation.libelle}} = {{cell.row.data.ratioUF}} KG">
          {{cell.row.data.quantiteAFacturer | number:'1.4-4'}} {{cell.row.data.uniteDeFacturation.abreviation | titlecase}}
        </div>
        <div *dxTemplate="let cell of 'templateQteACommander'" class="text-left"
             title="Quantité à commander, 1 {{cell.row.data.uniteDeCommande.libelle}} = {{cell.row.data.ratioUC}} KG">
          {{cell.row.data.quantiteACommander | number:'1.4-4'}} {{cell.row.data.uniteDeCommande.abreviation | titlecase}}
        </div>
        <div *dxTemplate="let cell of 'templateQteAjustee'" class="save-color cursor text-left">
          <span
            class="space">{{cell.row.data.quantiteACommanderAjustee | number:'1.4-4'}}  {{cell.row.data.uniteDeCommande.abreviation | titlecase}}</span>
          <span *ngIf="cell.row.data.quantiteACommanderAjustee < cell.row.data.minimumDeCommande">
            <i class="fas fa-cart-arrow-down danger-color space"
               title="Minimum de commande {{cell.row.data.minimumDeCommande}} {{cell.row.data.uniteDeCommande.libelle}}"></i>
          </span>
          <span *ngIf="isConditionneParInError(cell.row.data)">
            <i class="fas fa-box-open danger-color space"
               title="Conditionné par {{cell.row.data.conditionnePar}} {{cell.row.data.uniteDeCommande.libelle}}"></i>
          </span>
        </div>
        <div *dxTemplate="let cell of 'templateBesoinUT'" class="text-left"
             title="1 {{cell.row.data.uniteTechnique.libelle}} = {{cell.row.data.ratioUT}} KG">
          {{cell.row.data.quantiteBesoin | number:'1.4-4'}} {{cell.row.data.uniteTechnique.libelle | titlecase}}
        </div>
        <div *dxTemplate="let cell of 'templatePrixTotal'" class="text-left">
          {{cell.row.data.prixTotal | currency:'EUR':'symbol':'1.4-4'}}
        </div>
        <div *dxTemplate="let cell of 'templateStock'" class="text-left">
          <span>{{cell.row.data.quantiteStock }} {{cell.row.data.uniteDeCommande.libelle | titlecase }}</span>
        </div>
        <div *dxTemplate="let cell of 'templateRemarque'" class="save-color cursor text-left">
          {{ cell.row.data.commentaire }}
        </div>

        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-paging [pageSize]="100"></dxo-paging>
        <dxo-pager
          [showPageSizeSelector]="true"
          [showNavigationButtons]="false"
          [allowedPageSizes]="[20, 50, 100, 200]"
          [visible]="true"
          [showInfo]="true"
          infoText="{2} article(s)">
        </dxo-pager>
      </dx-data-grid>
    </div>
    <div class="border-footer">
      <div *ngIf="_bonCf.fournisseurFdpActif" class="row">
        <div class="col-md-11 text-right">
          <i class="fas fa-truck danger-color cursor pulse"
             *ngIf="getFrancoDePort(_bonCf,_lignesBonCf)>0"
             [title]="getTooltipFraisDePort(_bonCf)"></i>
          Frais de port
        </div>
        <div class="col-md-1 text-right border">
          {{getFrancoDePort(_bonCf, _lignesBonCf) | currency:'EUR':'symbol':'1.4-4'}}
        </div>
      </div>
      <div class="row">
        <div class="col-md-11 text-right">Montant HT</div>
        <div class="col-md-1 text-right border">{{_bonCf.montantHT | currency:'EUR':'symbol':'1.4-4'}}</div>
      </div>
      <div class="row">
        <div class="col-md-11 text-right">TVA</div>
        <div class="col-md-1 text-right border">{{tva | currency:'EUR':'symbol':'1.4-4'}}</div>
      </div>
      <div class="row">
        <div class="col-md-11 text-right">Montant TTC</div>
        <div
          class="col-md-1 text-right border">{{_bonCf.montantHT + tva  | currency:'EUR':'symbol':'1.4-4'}}</div>
      </div>
    </div>

    <div class="d-flex mg-t-5">
      <div class="mr-auto"></div>
      <div>

          <span class="mg-r-5">
            <yo-button iconClass="fa fa-home"
                       *ngIf="auth2Svc.isSiteLocal(_bonCf.site.id)"
                       (onClick)="updateDeliveryDate()"
                       label="Modifier l'adresse de livraison"></yo-button>
          </span>

        <span class="mg-r-5">
            <yo-button iconClass="fas fa-print" (onClick)="imprimerBonDeCommande()"
                       label="Imprimer"></yo-button>
          </span>

        <span class="mg-r-5">
            <yo-button iconClass="fas fa-save"
                       buttonClass="cta-success"
                       *ngIf="auth2Svc.isSiteLocal(_bonCf.site.id)"
                       (onClick)="sauverBonCommande()"
                       label="Enregistrer"></yo-button>
          </span>

        <yo-button iconClass="fas fa-times"
                   buttonClass="cta-inner-delete"
                   (onClick)="closeDialog()" label="Fermer"></yo-button>
      </div>
    </div>

    <div *ngIf="onModif">
      <p>
        🗣️<em> N'oubliez pas d'enregistrer.</em>
      </p>
    </div>

  </div>
</dx-popup>

<!--AJOUTER UN ARTICLE FOURNISSEUR À LA PROPOSITION DE COMMANDE-->
<yo-bc-add-article-fournisseur
  (onSave)="addArticles($event)">
</yo-bc-add-article-fournisseur>

<yo-bc-edit-delivery-address
  [bonCf]="_bonCf"
  [displayDialog]="displayDialogEditDeliveryDate"
  (onClose)="closeDialogEditDeliveryDate($event)"
></yo-bc-edit-delivery-address>


<!--TEMPLATE DU CALENDRIER POUR LE HIGHLIGHT DES DATES QUI CORRESPONDENT AUX JOURS DE LIVRAISON DU SECTEUR FOURNISSEUR SÉLECTIONNÉ-->
<ng-template #tplDateMenuCompo let-isDateFournisseur="isDateFournisseur" let-date="date">
  <ng-container *ngIf="isDateFournisseur">
    <div class="selected-day" title="Jour de livraison paramétré pour ce fournisseur"
    >{{date.day}}</div>
  </ng-container>


  <ng-container *ngIf="!isDateFournisseur">
    <div title="Jour de livraison NON paramétré pour ce fournisseur">{{date.day}}</div>
  </ng-container>
</ng-template>

<!--TEMPLATE FRAIS DE PORT-->
<ng-template #tplFraisDePort let-fraisDePort="fraisDePort">

  <div class="row" *ngIf="_bonCf.fournisseurFdpActif">
    <div class="col-md-8 text-left">
    </div>
    <div class="col ">
      <div class="row">
        <div class="col-md-8 text-right">
          <i class="mg-r-5 fas fa-exclamation-triangle danger-color cursor pulse" *ngIf="fraisDePort>0"
             [title]="getTooltipFraisDePort(_bonCf)"></i>
          Frais de port
        </div>
        <div class="col text-right border">{{fraisDePort | currency:'EUR':'symbol':'1.4-4'}}</div>
      </div>
    </div>
  </div>

</ng-template>

