import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import {DxListComponent} from "devextreme-angular";

export type ModeEnum = "list" | "tree" | "groupedList";

export class ListItem {
  data: any;
  parent: ListItem;
  children: ListItem[];
  depth?: number;
  selected?: boolean;
  hidden?: boolean;
  collapsed?: boolean;

  constructor(data, selected) {
    this.data = data;
    this.selected = selected;
  }
}

@Component({
  selector: 'yo-tree-list',
  templateUrl: './tree-list.component.html',
  styleUrls: ['./tree-list.component.scss']
})
export class TreeListComponent implements OnInit, OnChanges {

  @Input() mode: ModeEnum = "list";
  @Input() data: any[] = [];
  @Input() key: string;
  @Input() displayValue: string;
  @Input() childValue: string;
  @Input() selectedItemKeys: any[] = [];
  @Input() selectedItems: any[] = [];
  @Input() recursif: boolean = true;
  @Input() returnData: boolean = false;

  @Output() onValueChange = new EventEmitter;

  @ViewChild("dxList") dxList: DxListComponent;

  _selectAllValue: boolean = false;
  _rows: ListItem[] = [];
  _groups: ListItem[] = [];
  _filter: string;

  _selectedItems: any[] = []

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initData(changes?.data?.currentValue);
  }

  toListItem = (item, parent): ListItem => {
    return {
      data: item,
      selected: this.selectedItemKeys.includes(item[this.key]) || this._selectedItems.includes(item[this.key]),
      parent: parent,
      collapsed: false,
      depth: parent ? parent.depth + 1 : 0,
      children: []
    }
  }

  initData = (data: any[]) => {
    if (!data)
      return;

    this._filter = "";
    this._rows = [];
    this._groups = [];
    this._selectAllValue = false;
    this._selectedItems = this.selectedItems.map(item => item[this.key]);

    switch (this.mode) {
      case "list":
        this._rows = data.map(item => this.toListItem(item, undefined));
        this._groups = this._rows;
        break;
      case "tree":
      case "groupedList":
        this._rows = this.flatData(data, undefined);
        this._groups = this._rows.filter(item => item.parent == undefined);
        break;
    }

    this.updateSelectAllStatus();
  }

  flatData = (data: any[], parent: ListItem) => {
    if (!data)
      return [];
    return (data as any).flatMap(item => {
      let listItem = this.toListItem(item, parent);
      if (parent)
        parent.children.push(listItem);
      if (listItem.selected)
        this.updateParentValue(listItem);
      if (item.hasOwnProperty(this.childValue))
        return [listItem, ...this.flatData(item[this.childValue], listItem)];
      else
        return [listItem];
    })
  }

  getSelectedItemKeys = (): any[] => {
    return this.getSelectedItems().map(item => item.data[this.key]);
  }

  getSelectedItemDatas = (): any[] => {
    return this.getSelectedItems().map(item => item.data);
  }

  getSelectedItems = (): any[] => {
    switch (this.mode) {
      case "groupedList":
        return this._rows.filter(item => item.selected && !(item.children?.length > 0));
      default:
        return this._rows.filter(item => item.selected);
    }
  }

  onSelectAll = () => {
    this._rows.forEach(item => {
      if (!item.hidden)
        item.selected = this._selectAllValue;
    })
    this.onValueChange.emit(this.returnData ? this.getSelectedItemDatas() : this.getSelectedItemKeys());
  }

  onCheckboxChecked = (item: ListItem) => {
    if (this.recursif) {
      this.updateParentValue(item);
      this.updateChildValue(item);
    }

    let currentSelection = this.returnData ? this.getSelectedItemDatas() : this.getSelectedItemKeys();
    this.updateSelectAllStatus(currentSelection);

    this.onValueChange.emit(currentSelection);
  }

  updateSelectAllStatus = (currentSelection ?: ListItem[]) => {
    if (!currentSelection)
      currentSelection = this._rows.filter(item => !item.hidden);
    let selectedLength = currentSelection.filter(item => item.selected).length;

    if (selectedLength == 0)
      this._selectAllValue = false;
    else if (selectedLength >= currentSelection.length)
      this._selectAllValue = true;
    else
      this._selectAllValue = undefined;
  }

  updateParentValue = (item: ListItem) => {
    let working = item.parent;

    if (!working || !working.children)
      return;

    let selectedCount = working.children.filter(item => item.selected == true).length;
    working.selected = this.getSelectedValue(working.children.length, selectedCount);
    this.updateParentValue(working);
  }

  getSelectedValue = (childCount, selectedCount) => {
    if (selectedCount == 0)
      return false;
    else if (selectedCount == childCount)
      return true;
    else
      return undefined;
  }

  updateChildValue = (item: ListItem) => {
    if (!(item?.children?.length > 0))
      return;

    item.children.forEach(child => {
      child.selected = item.selected;
      this.updateParentValue(child);
    });
  }

  onFilter = ($event: any) => {
    let value = this._filter.toLowerCase();
    this._groups.forEach(item => {
      this.applyFilter(item, value);
    })
    this.updateSelectAllStatus()
  }

  applyFilter = (item: ListItem, filter) => {
    let hide = !(item.data[this.displayValue].toLowerCase().includes(filter));
    if (item.children?.length > 0)
      item.children.forEach(child => {
        this.applyFilter(child, hide ? filter : "");
        if (!child.hidden)
          hide = false;
      })
    item.hidden = hide;
  }

  collapseGroup(ligne: ListItem, value ?: boolean) {
    if (value == undefined)
      value = !ligne.collapsed;
    ligne.collapsed = value;
    ligne?.children.forEach(child => {
      this.collapseGroup(child, value);
    });
  }

  simulateCheckboxClick(ligne: ListItem) {
    ligne.selected = !ligne.selected;
    this.onCheckboxChecked(ligne);
  }

  clearSelection = () => {
    this._filter = "";
    this._rows.forEach(item => {
      item.selected = false;
    });
    this._selectedItems = [];
    this._selectAllValue = false;
  };
}


