import {Component, OnDestroy, OnInit} from '@angular/core';
import {CmcPlcSupplier} from '../cmc-points-de-livraison-resolver.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {ContratsMenusConvivesService} from '../../../../core/services/gestioncontrats/contrats-menus-convives.service';
import {RoutemapService} from '../../../../core/services/routemap.service';
import {SelectItem} from 'primeng/api';
import {PointDeLivraisonService} from '../../../../core/services/entities/point-de-livraison.service';
import {UtilsService} from '../../../../core/utils/utils.service';
import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';
import {PointDeLivraisonDTO} from '../../../../core/dtos/point-de-livraison-d-t-o';
import {ContratMenuConviveDTO} from '../../../../core/dtos/contratmenuconvive-dto';
import {ContratMenuConviveRepasDTO} from '../../../../core/dtos/contratmenuconviverepas-dto';
import {DecoupageRepasDTO} from '../../../../core/dtos/decoupagerepas-dto';
import {ContratMenuConviveDecoupageDTO} from '../../../../core/dtos/contratmenuconvivedecoupage-dto';
import {HELP_FOLDERS, JourSemaine, MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {RepasDTO} from '../../../../core/dtos/repas-dto';
import {cloneDeep as _cloneDeep, find as _find} from 'lodash';
import {DialogMsgSupplier, Paragraphe} from '../../../../core/suppliers/dialog-msg-supplier';
import {ContratMenuConviveDecoupage__PlcDTO} from '../../../../core/dtos/contratmenuconvivedecoupage__plc-dto';
import {CmcdPlcService} from '../../../../core/services/entities/cmcd-plc.service';
import {CmcMnService} from '../../../../core/services/entities/cmc-mn.service';
import {ContratMenuConvive__ModeleNutriDTO} from '../../../../core/dtos/contratmenuconvive__modele-nutri-dto';
import {Cmcr__CmcCa__PlcDto} from '../../../../core/dtos/cmcr__cmcca__plc-dto';
import {animate, style, transition, trigger} from '@angular/animations';
import {DomSanitizer} from '@angular/platform-browser';
import {ToastService} from "../../../../core/services/technique/toast.service";
import {RegimeAlimentaireDTO} from "../../../../core/dtos/regime-alimentaire-dto";

@Component({
  selector: 'yo-cmc-point-de-livraison',
  templateUrl: './cmc-point-de-livraison.component.html',
  styleUrls: ['./cmc-point-de-livraison.component.scss'],
  animations: [
    trigger('triggerPeriode', [
      transition(':enter', [
        style({opacity: 0}),
        animate('0.7s', style({opacity: 1})),
      ]),
      transition(':leave', [
        animate('0.3s', style({opacity: 0}))
      ])
    ])
  ]
})
export class CmcPointDeLivraisonComponent implements OnInit, OnDestroy {

  pointDeLivraison: PointDeLivraisonDTO;
  contratMenuConvive: ContratMenuConviveDTO;
  subscriptionRoute: Subscription;


  cmcdPlcList: ContratMenuConviveDecoupage__PlcDTO[] = [];
  cmcrCmcCaPlcList: Cmcr__CmcCa__PlcDto[] = [];
  periode: ContratMenuConvive__ModeleNutriDTO;

  rows: ContratMenuConviveRepasDTO[];
  cols: DecoupageRepasDTO[];
  rowGroupMetadata: any;
  cells: ContratMenuConviveDecoupageDTO[] = [];


  cellWidth = 180;
  colRepasWidth = 120;
  colRegimeWidth = 80;

  joursSemaine: JourSemaine[] = [];
  selectedJoursSemaine: JourSemaine[] = [];
  repasList: RepasDTO[] = [];
  regimeList: RegimeAlimentaireDTO[] = [];
  selectedRepasList: RepasDTO[] = [];
  selectedRegimeList: RegimeAlimentaireDTO[] = [];
  decoupageRepasList: DecoupageRepasDTO[];
  selectedDecoupageRepasList: DecoupageRepasDTO[];
  majTauxDePrisePrev: number;
  maxPlats: number;
  platList: SelectItem[] = [];
  selectedPlatList: number[] = [];

  majEffectifPrev: number;

  mapTaillesComposante: Map<string, number> = new Map();
  displayEffectifDialog = false;
  displayCoutDialog = false;
  displayTauxDePriseDialog = false;
  majCoutPrev: number;

  // bouton d'édition
  itemsEditPlanning = [{
    label: "Modification en masse : ",
    items: [
      {label: 'Effectifs ', command: event => this.displayEffectifDialog = true},
      {label: 'Coûts ', command: event => this.displayCoutDialog = true},
      {label: 'Taux de prise ', command: event => this.displayTauxDePriseDialog = true}
    ]
  }];

  pathFile: string = HELP_FOLDERS.CONTRATS_MENUS_CONVIVES + '/contrats-menus-convives-point-livraison';

  constructor(public sanitizer: DomSanitizer,
              public utils: UtilsService,
              public cmcMnSvc: CmcMnService,
              public gds: GenericDatagridService,
              private route: ActivatedRoute,
              private contratsMenusConvivesSvc: ContratsMenusConvivesService,
              private routeMapSvc: RoutemapService,
              private pdlSvc: PointDeLivraisonService,
              private cmcdPlcSvc: CmcdPlcService,
              private toastSvc: ToastService) {
  }

  ngOnInit() {
    this.iniRouteData();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
  }

  iniRouteData = () => {
    this.subscriptionRoute = this.route.data
      .subscribe((data: { cmcPlcSupplier: CmcPlcSupplier }) => {

        this.contratMenuConvive = data.cmcPlcSupplier.contratMenuConvive;
        this.pointDeLivraison = data.cmcPlcSupplier.selectedPointDeLivraison;
        this.periode = data.cmcPlcSupplier.selectedPeriode;

        // on ne fait rien sur cette page si on n'a pas le point de livraison ou le cmc renseigné
        if (!this.utils.isNullOrEmpty(this.pointDeLivraison) && !this.utils.isNullOrEmpty(this.contratMenuConvive)) {

          this.rows = data.cmcPlcSupplier.rows;
          this.cols = data.cmcPlcSupplier.cols;
          this.cells = data.cmcPlcSupplier.cmcdList;
          this.cmcdPlcList = data.cmcPlcSupplier.cmcdPlcList;
          this.cmcrCmcCaPlcList = data.cmcPlcSupplier.cmcrCmcCaPlcList;
          this.joursSemaine = data.cmcPlcSupplier.joursSemaine;
          this.repasList = data.cmcPlcSupplier.repasList;
          this.regimeList = data.cmcPlcSupplier.cmccaList.map(cmcca => cmcca.regimeAlimentaire);
          this.maxPlats = data.cmcPlcSupplier.maxPlats;

          this.decoupageRepasList = _cloneDeep(this.cols);
          this.selectedDecoupageRepasList = [];
          this.selectedPlatList = [];
          this.platList = this.getPlatList(this.maxPlats);
          this.rowGroupMetadata = this.utils.updateRowGroupMetaData(this.rows, 'idJourSemaine');

          this.repasList.forEach(repas => {
            repas.regimesAlimentaire = this.regimeList;
          });

          this.computeTailles(this.cmcdPlcList, this.cmcrCmcCaPlcList);
        }
      });
  };

  help = (): DialogMsgSupplier => {

    let dms = new DialogMsgSupplier();
    dms.title = `Taux de prise prévisionnels`;
    dms.logo = 'fa fa-question-circle  yoni-color';

    let p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
      `Saisie des effectifs et des taux de prise prévisionnels pour "${this.pointDeLivraison.libelle}" sur la prestation "${this.contratMenuConvive.libelle}" .`,
    ];

    dms.content = {
      intro: ``,
      paragraphes: [p1]
    };

    return dms;
  };

  savePlanning = () => {
    this.cmcdPlcSvc.saveGrille(this.contratMenuConvive, this.pointDeLivraison, this.cmcdPlcList, this.cmcrCmcCaPlcList).subscribe(response => {
      if (!this.utils.isResponseSupplierError(response))
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Effectifs prévisionnels pour "${this.contratMenuConvive.libelle} - ${this.pointDeLivraison.libelle}" enregistrés avec succès`);
    });
  };

  getCell = (row: ContratMenuConviveRepasDTO, col: DecoupageRepasDTO) =>
    this.cells.find(cell => cell.contratMenuConviveRepasId === row.id && cell.decoupageRepasId === col.id);

  computeTailles = (cmcdPlcList: ContratMenuConviveDecoupage__PlcDTO[], cmcrCmcCaPlcList: Cmcr__CmcCa__PlcDto[]) => {
    this.mapTaillesComposante = new Map();

    if (!this.utils.isCollectionNullOrEmpty(cmcdPlcList)) {
      cmcdPlcList.forEach(value => {
        const keyComposanteTaille = this.keyComposanteTaille(value.idRepas, value.idJourSemaine);
        const valComposanteTaille = this.mapTaillesComposante.get(keyComposanteTaille);
        const cmcrCmcCaPlcs: Cmcr__CmcCa__PlcDto[] = cmcrCmcCaPlcList
          .filter(item => item.idJourSemaine == value.idJourSemaine && item.idRepas == value.idRepas);
        const calcTailleComposante = (cmcrCmcCaPlcs && value.nombreChoixMaximum > cmcrCmcCaPlcs.length) ?
          value.nombreChoixMaximum * 40 + 45 : cmcrCmcCaPlcs.length * 40 + 45;
        // si existe
        if (valComposanteTaille) {
          // on met à jour si taille calculee plus grande que taille actuelle
          if (valComposanteTaille < calcTailleComposante) {
            this.mapTaillesComposante.set(keyComposanteTaille, calcTailleComposante);
          }
        }
        // si n'existe pas
        else {
          this.mapTaillesComposante.set(keyComposanteTaille, calcTailleComposante);
        }
      });
    }
  };

  getPlatList = (maxPlats: number) => {
    const slots = this.utils.getSlots(this.maxPlats);
    const platList = [];

    for (const slot of slots) {
      platList.push({label: 'Plat ' + (slot + 1), value: (slot + 1)});
    }
    return platList;
  };

  getTailleComposante = (rowData: ContratMenuConviveRepasDTO) => {
    const composanteTaille = this.mapTaillesComposante.get(this.keyComposanteTaille(rowData.repasId, rowData.idJourSemaine));
    return composanteTaille ? composanteTaille : 40;
  };

  keyComposanteTaille = (repasId: number, idJourSemaine: number) => repasId + '-' + idJourSemaine;

  getCmcdPlc = (cell: ContratMenuConviveDecoupageDTO, ordre: number) =>
    this.cmcdPlcList.find(cmcdPlc => cmcdPlc.idContratMenuConviveDecoupage === cell.id && cmcdPlc.ordre === ordre);

  getAllCmcrCmcCaPlc = (row: ContratMenuConviveRepasDTO) =>
    this.cmcrCmcCaPlcList.filter(item => item.idContratMenuConviveRepas === row.id);

  saveEffectif = ($event) => {
    const {selectedJoursSemaine, selectedRepasList, selectedRegimeList, majEffectifPrev} = $event;
    if (!this.utils.isCollectionNullOrEmpty(this.cmcrCmcCaPlcList)) {
      for (let jourSemaine of selectedJoursSemaine) {
        for (let r of selectedRepasList) {
          for (let regime of selectedRegimeList) {
            const cmcrCmcCaPlc = _find(this.cmcrCmcCaPlcList, {
              'idRepas': r.id,
              'idJourSemaine': jourSemaine.value,
              'idRegime': regime.id
            }) as Cmcr__CmcCa__PlcDto;

            if (!this.utils.isNullOrEmpty(cmcrCmcCaPlc)) cmcrCmcCaPlc['effectifPrevisionnel'] = majEffectifPrev;
          }
        }
      }
    }
    this.closeEffectifDialog();
  };

  saveCout = ($event) => {
    const {selectedJoursSemaine, selectedRepasList, selectedRegimeList, majCoutPrev} = $event;
    if (!this.utils.isCollectionNullOrEmpty(this.cmcrCmcCaPlcList)) {
      for (let jourSemaine of selectedJoursSemaine) {
        for (let r of selectedRepasList) {
          for (let regime of selectedRegimeList) {
            const cmcrCmcCaPlc = _find(this.cmcrCmcCaPlcList, {
              'idRepas': r.id,
              'idJourSemaine': jourSemaine.value,
              'idRegime': regime.id
            }) as Cmcr__CmcCa__PlcDto;

            if (!this.utils.isNullOrEmpty(cmcrCmcCaPlc)) cmcrCmcCaPlc['coutPrevisionnel'] = majCoutPrev;
          }
        }
      }
    }
    this.closeCoutDialog()
  };

  updateTauxDePrisePrev = (selectedJoursSemaine: JourSemaine[], selectedRepasList: RepasDTO[], selectedDecoupageRepasList: DecoupageRepasDTO[],
                           selectedPlatList: number[], majTauxDePrisePrev: number) => {

    if (!this.utils.isCollectionNullOrEmpty(this.rows)) {
      for (const jourSemaine of selectedJoursSemaine) {
        for (const repas of selectedRepasList) {

          const cmcr = _find(this.rows, {
            'repasId': repas.id,
            'idJourSemaine': jourSemaine.value
          }) as ContratMenuConviveRepasDTO;

          if (!this.utils.isNullOrEmpty(cmcr)) {
            for (const decoupageRepas of selectedDecoupageRepasList) {
              const cmcd = _find(this.cells, {
                'decoupageRepasId': decoupageRepas.id,
                'repasId': repas.id,
                'contratMenuConviveRepasIdJourSemaine': jourSemaine.value
              });

              if (!this.utils.isNullOrEmpty(cmcd) && !this.utils.isCollectionNullOrEmpty(cmcd.contratMenuConvivePlatDTOList)) {
                for (const cmcdPlc of this.cmcdPlcList) {
                  for (const selectedPlat of selectedPlatList) {
                    if (selectedPlat === cmcdPlc.ordre && cmcdPlc.idContratMenuConviveDecoupage === cmcd.id) {
                      // on change le taux de prise en affichage
                      cmcdPlc.tauxDePrise = majTauxDePrisePrev;

                    }
                  }
                }
              }
            }
          }
        }
      }
      this.displayTauxDePriseDialog = false;
    }
  };

  closeEffectifDialog = () => {
    this.displayEffectifDialog = false;
  };

  closeCoutDialog = () => {
    this.displayCoutDialog = false;
  };
}
