import {Component, OnDestroy, OnInit} from '@angular/core';
import {ContratMenuConviveDTO} from '../../../core/dtos/contratmenuconvive-dto';
import {Subscription} from 'rxjs';
import {RoutemapService} from '../../../core/services/routemap.service';
import {ContratsMenusConvivesService} from '../../../core/services/gestioncontrats/contrats-menus-convives.service';
import {ActivatedRoute} from '@angular/router';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {GenericFormService} from '../../../core/services/generics/generic-form.service';
import {DialogMsgSupplier, Paragraphe} from '../../../core/suppliers/dialog-msg-supplier';
import {ModeleNutritionnelDTO} from '../../../core/dtos/modele-nutritionnel-dto';
import {CmcPlanningSupplier} from './cmc-planning-resolver.service';
import {ContratMenuConviveRepasDTO} from '../../../core/dtos/contratmenuconviverepas-dto';
import {DecoupageRepasDTO} from '../../../core/dtos/decoupagerepas-dto';
import {ContratMenuConviveDecoupageDTO} from '../../../core/dtos/contratmenuconvivedecoupage-dto';
import {cloneDeep as _cloneDeep, find as _find} from 'lodash'
import {SelectItem} from 'primeng/api';
import {DATEPICKER_FR, HELP_FOLDERS, JourSemaine, MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {RepasDTO} from '../../../core/dtos/repas-dto';
import {ContratMenuConvive__ModeleNutriDTO} from '../../../core/dtos/contratmenuconvive__modele-nutri-dto';
import {CmcMnService} from '../../../core/services/entities/cmc-mn.service';
import {animate, style, transition, trigger} from '@angular/animations';
import * as moment from 'moment';

import {DomSanitizer} from '@angular/platform-browser';
import {ContratMenuConvive__PlcDTO} from '../../../core/dtos/contratmenuconvive__plc-dto';
import {ContratMenuConvivePlatDTO} from '../../../core/dtos/contratmenuconviveplat-dto';
import {confirm} from "devextreme/ui/dialog";
import {ToastService} from "../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-cmc-planning',
  templateUrl: './cmc-planning.component.html',
  styleUrls: ['./cmc-planning.component.scss'],
  animations: [
    trigger('triggerPeriode', [
      transition(':enter', [
        style({opacity: 0}),
        animate('0.7s', style({opacity: 1})),
      ]),
      transition(':leave', [
        animate('0.3s', style({opacity: 0}))
      ])
    ])
  ]
})
export class CmcPlanningComponent implements OnInit, OnDestroy {

  mapTaillesComposante: Map<string, number> = new Map();

  // bouton d'édition
  itemsEditPlanning = [{
    label: "Modification en masse : ",
    items: [
      {label: 'Effectifs ', command: event => this.displayEffectifDialog = true},
      {label: 'Coûts ', command: event => this.displayCoutTheoriqueDialog = true},
      {label: 'Taux de prise ', command: event => this.displayTauxDePriseDialog = true}
    ]
  }];

  contratMenuConvive: ContratMenuConviveDTO;
  subscriptionRoute: Subscription;
  periodes: ContratMenuConvive__ModeleNutriDTO[] = [];
  selectedPeriode: ContratMenuConvive__ModeleNutriDTO;
  plansAlimentaires: ModeleNutritionnelDTO[];
  selectedPlanAlimentaire: ModeleNutritionnelDTO;
  displayAddPlanning: boolean = false;
  dateDebutPlanning: Date = new Date();
  localeFr = DATEPICKER_FR;
  prochaineDateDisponible: Date = new Date();
  validAddPlaning = true;
  errorAddPlanning = '';

  rows: ContratMenuConviveRepasDTO[];
  cols: DecoupageRepasDTO[];
  cells: ContratMenuConviveDecoupageDTO[];
  rowGroupMetadata: any;


  cellWidth = 140;
  colRepasWidth = 150;
  displayEffectifDialog = false;
  displayCoutTheoriqueDialog = false;
  displaySaveDialog = false;
  displayTauxDePriseDialog = false;

  majPrixRepasTheorique: number;
  majEffectifPrev: number;
  majTauxDePrisePrev: number;
  joursSemaine: JourSemaine[];
  selectedJoursSemaine: JourSemaine[];
  repasList: RepasDTO[];
  selectedRepasList: RepasDTO[];
  decoupageRepasList: DecoupageRepasDTO[];
  selectedDecoupageRepasList: DecoupageRepasDTO[];
  maxPlats: number;
  platList: SelectItem[] = [];
  selectedPlatList: number[] = [];
  cmcPlcList: ContratMenuConvive__PlcDTO[] = [];
  selectedCmcPlcList: ContratMenuConvive__PlcDTO[] = [];
  applyPrevOnPlc = false;

  pathFile: string = HELP_FOLDERS.CONTRATS_MENUS_CONVIVES + '/contrats-menus-convives-planning';

  constructor(private route: ActivatedRoute,
              private contratsMenusConvivesSvc: ContratsMenusConvivesService,
              public cmcMnSvc: CmcMnService,
              public domSanitizerSvc: DomSanitizer,
              public routeMapSvc: RoutemapService,
              public utils: UtilsService,
              public gds: GenericDatagridService,
              public gfs: GenericFormService,
              private toastSvc: ToastService) {
  }

  helpPeriodes = (): DialogMsgSupplier => {

    let dms = new DialogMsgSupplier();
    dms.title = `Périodes`;
    dms.logo = 'fa fa-question-circle  yoni-color';

    let p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
      `Une période de planning est un modèle de menu associé à une prestation avec une date de début et une date de fin.`,
      `On peut ajouter une période en cliquant sur 'Créer une période' dans la liste déroulante.`,

    ];

    let sp1 = new Paragraphe();
    sp1.lines = [
      `Lorsqu'une période est créée, elle récupére les effectifs et les couts prévisionnels de la période précédente (si elle exsite).`,
      `Attention, la période précédente doit avoir le même modèle de menu pour que cela fonctionne.`,
      `Il en sera de même pour les points de livraison rattachés à la prestation (on récupére les anciens effectifs / couts / taux de prises prévisionnels).`,
    ];
    p1.sousParagraphes = [sp1];


    dms.content = {
      intro: ``,
      paragraphes: [p1]
    };

    return dms;
  };


  initForm() {

  }

  addPeriodePlanning = (plannings: ContratMenuConvive__ModeleNutriDTO[]) => {

    const addPeriodePlanning = this.cmcMnSvc.createEmptyDTO();
    addPeriodePlanning.modeleNutritionnelLibelle = 'Ajouter un planning';
    plannings.unshift(addPeriodePlanning);

  };

  ngOnInit() {


    this.subscriptionRoute = this.route.data.subscribe((data: { cmcPlanningSupplier: CmcPlanningSupplier }) => {

      this.contratMenuConvive = data.cmcPlanningSupplier.cmc;
      this.periodes = data.cmcPlanningSupplier.periodes ? data.cmcPlanningSupplier.periodes : [];
      this.plansAlimentaires = data.cmcPlanningSupplier.plansAlimentaires ? data.cmcPlanningSupplier.plansAlimentaires : [];


      // ne pas proposer le plan alimentaire en cours pour la creation d'une nouvelle periode de planning
      // const lastPeriode = this.periodes.filter(periode => this.utils.isNullOrEmpty(periode.dateFin))[0];
      // if (!this.utils.isNullOrEmpty(lastPeriode)) {
      //   this.plansAlimentaires = this.plansAlimentaires.filter(pa => pa.id != lastPeriode.modeleNutritionnelId);
      // }


      this.prochaineDateDisponible = data.cmcPlanningSupplier.prochaineDateDisponible;
      this.dateDebutPlanning = moment(this.prochaineDateDisponible).isBefore(moment(new Date())) ? new Date() : _cloneDeep(this.prochaineDateDisponible);

      // ajouter la ligne ajouter une période dans la liste deroulante periode
      this.addPeriodePlanning(this.periodes);

      // par defaut on affiche le planning avec la periode la plus récente
      this.loadMostRecentPeriode(this.periodes);


    });

  }

  /**
   *  par defaut on affiche le planning avec la periode la plus récente
   */
  loadMostRecentPeriode = (periodes: ContratMenuConvive__ModeleNutriDTO[]) => {
    if (periodes && periodes.length > 1) {
      this.selectedPeriode = periodes[1];
      this.changePeriode(this.selectedPeriode);
    }
  };


  savePlanning = () => {


    this.contratsMenusConvivesSvc.savePeriodePlanning(this.rows, this.selectedCmcPlcList, this.selectedPeriode).subscribe(response => {

      if (!this.utils.isResponseSupplierError(response)) {

        this.displaySaveDialog = false;
        this.applyPrevOnPlc = false;

        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Modèle de menu enregistré avec succès pour la période ${this.cmcMnSvc.getPeriodeLabelStr(this.selectedPeriode)}`);
      }
    });

  };


  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
  }


  updateCoutsTheoriques = (joursSemaine: JourSemaine[], repasList: RepasDTO[], prixRepasTheorique: number) => {

    if (!this.utils.isCollectionNullOrEmpty(this.rows)) {


      for (let jourSemaine of joursSemaine) {

        for (let repas of repasList) {

          const cmcr = _find(this.rows, {
            'repasId': repas.id,
            'idJourSemaine': jourSemaine.value
          }) as ContratMenuConviveRepasDTO;

          if (!this.utils.isNullOrEmpty(cmcr)) {
            cmcr.prixRepasTheorique = prixRepasTheorique;
          }

        }

      }

    }

    this.displayCoutTheoriqueDialog = false;

  };

  updateEffectifsPrev = (joursSemaine: JourSemaine[], repasList: RepasDTO[], effectifPrev: number) => {

    if (!this.utils.isCollectionNullOrEmpty(this.rows)) {


      for (let jourSemaine of joursSemaine) {

        for (let repas of repasList) {

          const cmcr = _find(this.rows, {
            'repasId': repas.id,
            'idJourSemaine': jourSemaine.value
          }) as ContratMenuConviveRepasDTO;

          if (!this.utils.isNullOrEmpty(cmcr)) {
            cmcr.effectifPrevu = effectifPrev;
          }

        }

      }

    }

    this.displayEffectifDialog = false;

  };

  isGridDisplayable = () => this.selectedPeriode && this.selectedPeriode.id > 0 && !this.utils.isCollectionNullOrEmpty(this.rows);

  changePeriode = (periode: ContratMenuConvive__ModeleNutriDTO) => {

    this.displayAddPlanning = false;

    if (periode) {
      if (periode.id === 0) {
        this.displayAddPlanning = true;
      } else {

        // show planning
        this.cmcMnSvc.loadPlanning(periode.id, 0, 1).subscribe(response => {

          this.rows = response.additionalProperties['rows'];
          this.cols = response.additionalProperties['cols'];
          this.cells = response.additionalProperties['cells'];
          this.joursSemaine = response.additionalProperties['joursOuvres'];
          this.repasList = response.additionalProperties['repasList'];
          this.decoupageRepasList = response.additionalProperties['cols'];
          this.maxPlats = response.additionalProperties['maxPlats'];
          this.cmcPlcList = response.additionalProperties['cmcPlcList'];
          this.rowGroupMetadata = this.utils.updateRowGroupMetaData(this.rows, 'idJourSemaine');

          this.platList = this.getPlatList(this.maxPlats);
          this.selectedPlatList = [];


          this.computeTailles(this.cells);

        });

      }

    }
  };

  getPlatList = (maxPlats: number) => {
    const slots = this.utils.getSlots(this.maxPlats);
    const platList = [];

    for (const slot of slots) {
      platList.push({label: 'Plat ' + (slot + 1), value: (slot + 1)});
    }

    return platList;
  };

  changePlanAlimentaire({$event}: { $event: any }) {
  }

  createPeriode = () => {

    this.validAddPlaning = true;
    this.errorAddPlanning = '';
    if (this.utils.isNullOrEmpty(this.selectedPlanAlimentaire)) {
      this.validAddPlaning = false;
      this.errorAddPlanning = `Veuillez sélectionner un modèle de menu.<br>`;
    }
    if (this.utils.isNullOrEmpty(this.dateDebutPlanning)) {
      this.validAddPlaning = false;
      this.errorAddPlanning += `Veuillez sélectionner une date de début`;
    }

    if (this.validAddPlaning) {
      this.contratsMenusConvivesSvc.createPeriode(this.contratMenuConvive, this.selectedPlanAlimentaire, this.dateDebutPlanning).subscribe(response => {

        if (!this.utils.isResponseSupplierError(response)) {

          this.displayAddPlanning = false;

          this.periodes = response.additionalProperties['periodes'];
          this.addPeriodePlanning(this.periodes);
          this.selectedPeriode = this.utils.preSelectSingleList(this.periodes, response.one);

          // on grise le calendrier pour toutes les dates non disponibles
          this.prochaineDateDisponible = this.utils.convertNumberDateToDate(response.additionalProperties['prochaineDateDisponible']);
          // si la date sélectionnée est < à la date prochaine date dispo, la date sélectionnée sera = à la prochaine date dispo.
          this.dateDebutPlanning = moment(this.dateDebutPlanning).isBefore(this.prochaineDateDisponible) ? _cloneDeep(this.prochaineDateDisponible) : this.dateDebutPlanning;

          // charger le planning si la periode existe
          this.changePeriode(this.selectedPeriode);

          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'Période ajoutée avec succès');
        }

      });
    }


  };


  getCell = (rowData: ContratMenuConviveRepasDTO, col: DecoupageRepasDTO) => {


    if (!this.utils.isCollectionNullOrEmpty(this.cells)) {

      const elt = _find(this.cells, {'contratMenuConviveRepasId': rowData.id, 'decoupageRepasId': col.id});

      return elt ? elt : undefined;

    }
    return undefined;

  };

  removePeriode = async (selectedPeriode: ContratMenuConvive__ModeleNutriDTO) => {
    const result = confirm(`Etes vous sûr de vouloir supprimer la période "${this.cmcMnSvc.getPeriodeLabelStr(selectedPeriode)}" pour ${selectedPeriode.modeleNutritionnelLibelle}?`,
      'Suppression de période');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      this.cmcMnSvc.removePeriode(selectedPeriode).subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Période "${this.cmcMnSvc.getPeriodeLabelStr(selectedPeriode)}" pour ${selectedPeriode.modeleNutritionnelLibelle} supprimée avec succès`);
          this.periodes = this.periodes.filter(item => item.id != selectedPeriode.id);

          // on charge la période la plus récente
          this.loadMostRecentPeriode(this.periodes);
          this.selectedPeriode.dateFin = null;

          this.prochaineDateDisponible = this.utils.convertNumberDateToDate(response.additionalProperties['prochaineDateDisponible']);
          this.dateDebutPlanning = _cloneDeep(this.prochaineDateDisponible);
        }
      });
    }

  };

  computeTailles = (cmcdList: ContratMenuConviveDecoupageDTO[]) => {

    this.mapTaillesComposante = new Map();

    if (!this.utils.isCollectionNullOrEmpty(cmcdList)) {

      cmcdList.forEach(value => {

        const keyComposanteTaille = this.keyComposanteTaille(value.repasId, value.contratMenuConviveRepasIdJourSemaine);

        const valComposanteTaille = this.mapTaillesComposante.get(keyComposanteTaille);
        const calcTailleComposante = value.nombreChoixMaximum * 25 + 25;

        // si existe
        if (valComposanteTaille) {
          // on met à jour si taille calculee plus grande que taille actuelle
          if (valComposanteTaille < calcTailleComposante) {
            this.mapTaillesComposante.set(keyComposanteTaille, calcTailleComposante);
          }
        }
        // si n'existe pas
        else {
          this.mapTaillesComposante.set(keyComposanteTaille, calcTailleComposante);
        }
      });
    }
  };

  getTailleComposante = (rowData: ContratMenuConviveRepasDTO) => {

    const keyComposanteTaille = this.keyComposanteTaille(rowData.repasId, rowData.idJourSemaine);
    const composanteTaille = this.mapTaillesComposante.get(keyComposanteTaille);

    return composanteTaille ? composanteTaille : 40;

  };

  keyComposanteTaille = (repasId: number, idJourSemaine: number) => repasId + '-' + idJourSemaine;

  changeTauxDePrise = (plat: ContratMenuConvivePlatDTO, cell: ContratMenuConviveDecoupageDTO, cmcr: ContratMenuConviveRepasDTO, $event: any) => {

    for (const row of this.rows) {
      for (const cmcd of row.contratMenuConviveDecoupageDTOList) {
        for (const cmcp of cmcd.contratMenuConvivePlatDTOList) {
          if (cmcp.id === plat.id) {
            cmcp.tauxDePrise = $event;
            return;
          }
        }
      }
    }
  };

  updateTauxDePrisePrev = (selectedJoursSemaine: JourSemaine[], selectedRepasList: RepasDTO[], selectedDecoupageRepasList: DecoupageRepasDTO[], selectedPlatList: number[], majTauxDePrisePrev: number) => {

    if (!this.utils.isCollectionNullOrEmpty(this.rows)) {

      for (const jourSemaine of selectedJoursSemaine) {
        for (const repas of selectedRepasList) {

          const cmcr = _find(this.rows, {
            'repasId': repas.id,
            'idJourSemaine': jourSemaine.value
          }) as ContratMenuConviveRepasDTO;

          if(!this.utils.isNullOrEmpty(cmcr)){
            for (const decoupageRepas of selectedDecoupageRepasList) {

              const cmcd = _find(this.cells, {
                'decoupageRepasId': decoupageRepas.id,
                'repasId': repas.id,
                'contratMenuConviveRepasIdJourSemaine': jourSemaine.value
              });

              if (!this.utils.isNullOrEmpty(cmcd) && !this.utils.isCollectionNullOrEmpty(cmcd.contratMenuConvivePlatDTOList)) {

                for (const plat of cmcd.contratMenuConvivePlatDTOList) {
                  for (const selectedPlat of selectedPlatList) {
                    if (selectedPlat === plat.ordre) {

                      // on change le taux de prise en affichage
                      plat.tauxDePrise = majTauxDePrisePrev;

                      // on change aussi le taux de prise dans la liste d'element qui va etre passée au back
                      this.changeTauxDePrise(plat, cmcd, cmcr, majTauxDePrisePrev);
                    }
                  }
                }
              }
            }
          }
        }
      }
      this.displayTauxDePriseDialog = false;

    }
  };
}
