<div class="custom-button-container-left">
  <yo-help lang="fr" [file]="pathFile" [dialogMsgSupplier]="getHelp()" [width]="800"></yo-help>
  <p-selectButton [options]="lbdViews"
                  [(ngModel)]="selectedLbdView"
                  (onChange)="changeLbdView($event)">
  </p-selectButton>
  <div *ngIf="listeBesoin?.archive">
    <yo-button-current-step [workflowInstance]="workflowInstance" prefix="EN STATUT"
                            title="Cette liste de besoin n'est plus modifiable ! Elle est en statut {{workflowInstance?.libelleWorkflowStatut}}"></yo-button-current-step>
  </div>

  <yo-button
    tooltip="Appliquer les modifications à toutes les lignes selectionnées"
    label="Traitement de masse"
    iconClass="fas fa-edit"
    [disabled]="selectedBesoinsDenree.length === 0"
    (onClick)="openBulkEditionDenreeDialog()">
  </yo-button>

  <yo-button
    title="Exporter toute la liste de besoins"
    label="Tout exporter"
    iconClass="fas fa-edit"
    (onClick)="exportAll()">
  </yo-button>
</div>

<dx-data-grid
  id="gridContainer"
  [dataSource]="localdata"
  [showBorders]="true"
  [showRowLines]="true"
  [width]="utils.getWindowAvailableWidth(479)"
  [height]="utils.getWindowAvailableHeight(200)"
  [allowColumnResizing]="true"
  [rowAlternationEnabled]="false"
  columnResizingMode="widget"
  (onSelectionChanged)="onSelectionChangedDxGrid($event)"
  (onRowUpdated)="onRowUpdated($event)"
  (onRowPrepared)="onRowPrepared($event)"
  (onCellPrepared)="onCellPrepared($event)"
  (onEditingStart)="onEditingStart($event)"
  [noDataText]="noDataText"
  keyExpr="id"
  #grid>

  <dxi-column dataField="id" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="uniteDeProductionLibelle" caption="Unité de production" [width]="100"
              cellTemplate="udpCellTemplate"
              [allowEditing]="false"></dxi-column>
  <dxi-column dataField="produitDeclinaisonLibelle" caption="Déclinaison" [width]="200"
              headerCellTemplate="infoHeaderCellTemplate"
              cellTemplate="denreeCellTemplate"
              [allowEditing]="false"></dxi-column>
  <dxi-column dataField="denreeInitialeLibelle" caption="Déclinaison initiale" [width]="200"
              [allowEditing]="false"></dxi-column>
  <dxi-column dataField="catalogueAchatLibelle" caption="Article" [width]="200" [allowEditing]="isCellEditable(true)"
              headerCellTemplate="updateHeaderCellTemplate"
              cellTemplate="articleCellTemplate"
              editCellTemplate="editArticleCellTemplate">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="fournisseurLibelle" caption="Fournisseur" [width]="160"
              [allowEditing]="false"></dxi-column>

  <dxi-column dataField="besoin" [width]="120" [allowEditing]="false" cellTemplate="besoinCellTemplate">
    <dxo-format type="fixedPoint" [precision]="4"></dxo-format>
  </dxi-column>
  <dxi-column dataField="besoinAjuste" caption="Besoin ajusté" [width]="120" [allowEditing]="isCellEditable(true)"
              headerCellTemplate="updateHeaderCellTemplate"
              cellTemplate="besoinAjusteCellTemplate"
  >
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxi-validation-rule type="range" [min]="0"></dxi-validation-rule>
    <dxo-format type="fixedPoint" [precision]="4"></dxo-format>
  </dxi-column>
  <dxi-column dataField="qteUc" caption="Qte UC" [width]="140" [allowEditing]="false"
              cellTemplate="qteUcCellTemplate"
  >
  </dxi-column>
  <dxi-column dataField="qteUf" [width]="140" caption="Qte UF" cellTemplate="qteUfCellTemplate" [allowEditing]="false">
  </dxi-column>

  <dxi-column dataField="prixUnitaireUF" caption="PU" [width]="80" format="currency" alignment="right"
              [allowEditing]="false">
    <dxo-format type="currency" [precision]="4"></dxo-format>
  </dxi-column>
  <dxi-column dataField="prixTotal" caption="PU*Qte" [width]="100" alignment="right" [allowEditing]="false">
    <dxo-format type="currency" [precision]="4"></dxo-format>
  </dxi-column>
  <dxi-column dataField="dateAppro" [width]="100" dataType="date" alignment="right"
              headerCellTemplate="updateHeaderCellTemplate" [allowEditing]="isCellEditable(true)">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="dateLivFournisseur" caption="Date livraison" [width]="100" dataType="date" alignment="right"
              headerCellTemplate="updateHeaderCellTemplate" [allowEditing]="isCellEditable(true)">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="dateSortie" [width]="100" dataType="date" alignment="right"
              headerCellTemplate="updateHeaderCellTemplate" [allowEditing]="isCellEditable(true)">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="dateFabrication" [width]="100" dataType="date" alignment="right"
              headerCellTemplate="updateHeaderCellTemplate" [allowEditing]="isCellEditable(true)">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="dateConsommation" [width]="100" dataType="date" alignment="right"
              headerCellTemplate="updateHeaderCellTemplate" [allowEditing]="isCellEditable(true)">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="dateMenu" [width]="100" dataType="date" alignment="right"
              headerCellTemplate="updateHeaderCellTemplate">
  </dxi-column>
  <dxi-column dataField="zoneDeProdLibelle" caption="Atelier" [width]="160" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="equipeLibelle" caption="Equipe" [width]="160" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="qteStockEnKilo" caption="Qté en stock" cellTemplate="quantiteEnStockCellTemplate" [width]="160"
              [allowEditing]="false"></dxi-column>

  <!--  DATAGRID OPTION-->
  <dxo-selection mode="multiple"
                 [selectAllMode]="true"
                 [showCheckBoxesMode]="'always'">
  </dxo-selection>

  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-paging [pageSize]="10" [enabled]="false"></dxo-paging>
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>

  <dxo-editing mode="cell" [allowUpdating]="true" [confirmDelete]="false">
  </dxo-editing>

  <!--    GROUPING-->
  <dxo-summary>
    <dxi-group-item
      column="produitDeclinaisonLibelle"
      displayFormat="{0} denrées"
      summaryType="count"
    >
    </dxi-group-item>
    <dxi-group-item
      column="prixTotal"
      [valueFormat]="{'type':'currency','precision':4}"
      summaryType="sum"
      displayFormat="{0}"
      [showInGroupFooter]="true"
    >
    </dxi-group-item>
    <dxi-total-item
      column="prixTotal"
      [valueFormat]="{'type':'currency','precision':4}"
      displayFormat="{0}"
      summaryType="sum">
    </dxi-total-item>
    <dxi-total-item
      column="id"
      displayFormat="{0} éléments"
      showInColumn="uniteDeProductionLibelle"
      summaryType="count">
    </dxi-total-item>
  </dxo-summary>

  <!--  COLUMN CHOOSER-->
  <dxo-column-chooser mode="select" [enabled]="true"></dxo-column-chooser>

  <!-- UPDATE HEADER CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'updateHeaderCellTemplate'">
    <div title="colonne modifiable">
      {{cell.column.caption}}
    </div>
  </div>

  <!--  INFO HEADER CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'infoHeaderCellTemplate'">
    <div title="colonne ayant des informations supplémentaires">
      <i class="fas fa-info-circle mg-r-5"></i>{{cell.column.caption}}
    </div>
  </div>

  <!--  BESOIN CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'besoinCellTemplate'">
    {{cell.row.data.besoin | number:'1.4-4'}} {{cell.row.data.udmUt}}
  </div>

  <!-- ARTICLE CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'articleCellTemplate'">
    {{cell.row.data.catalogueAchatLibelle}} <i *ngIf="cell.row.data.withoutItemPrefered" title="Aucun article préféré sélectionné"
                                               class="warning fa-solid fa-triangle-exclamation"></i>
    <br/>
    <yo-info-lotmarche-article [uniteDeProductionId]="cell.row.data.uniteDeProductionId"
                               [lotMarchePdLumpCa]="cell.row.data.lmPdUpCa"></yo-info-lotmarche-article>

  </div>

  <!-- EDIT ARTICLE CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'editArticleCellTemplate'">

    <dx-drop-down-box
      [dropDownOptions]="dropDownOptions"
      [dataSource]="articlesDisponibles"
      [value]="cell.row.data.catalogueAchatId"
      displayExpr="produitArticle.libelle"
      valueExpr="id"
      (onFocusIn)="onFocusReplaceArticle($event)"
      contentTemplate="contentTemplate">

      <div *dxTemplate="let e of 'contentTemplate'">

        <yo-grille-remplacer-article
          [udpSfPrefereList]="udpSfPrefereList"
          [articlesDisponibles]="articlesDisponibles"
          [unicityIdForGroupeName]="selectedLbd.id"
          [idUdp]="selectedLbd.uniteDeProductionId"
          [selectedGacaViewModel]="selectedGacaViewModel"
          (updatePrixUnitaireUF)="updatePrixUnitaireLbd(e.component,$event)">
        </yo-grille-remplacer-article>

      </div>

    </dx-drop-down-box>

  </div>

  <!--  BESOIN AJUSTE CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'besoinAjusteCellTemplate'">
    {{cell.row.data.besoinAjuste | number:'1.4-4'}} {{cell.row.data.udmUt}}
  </div>

  <!--  QTE EN STOCK CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'quantiteEnStockCellTemplate'">
    {{cell.row.data?.qteStockEnKilo | number:'1.4-4'}} KILO
  </div>


  <!--  QTE UC CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'qteUcCellTemplate'">
    {{cell.row.data.qteUc | number:'1.4-4'}} {{cell.row.data.udmUc}}
  </div>

  <!--  QTE UF CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'qteUfCellTemplate'">
    {{cell.row.data.qteUf | number:'1.4-4'}} {{cell.row.data.udmUf}}
  </div>

  <!--  UNITE DE PROD CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'udpCellTemplate'">
    <yo-site [siteId]="cell.row.data.udpSiteId" [siteLibelleTooltip]="cell.row.data.udpSiteLibelle"></yo-site>
    {{cell.row.data.uniteDeProductionLibelle}}
  </div>

  <!--  DENREE CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'denreeCellTemplate'" (mouseenter)="showInfosDenree(cell.row.data,$event)"
       (mouseleave)="closeInfosDenree($event)" class="cursorHelp">
    <span *ngIf="cell.row.data.denreeNonapprovisionnee">
      <i title="Déclinaison non approvisionnée" class="fas fa-info-circle"></i>
    </span> {{cell.row.data.produitDeclinaisonLibelle}}
  </div>


</dx-data-grid>

<yo-legend-grid-dev-extreme
  [modificationManuelle]="true"
  [colonneModifiable]="true"
  [colonneInformations]="true"
  [colonneObligatoire]="false">
</yo-legend-grid-dev-extreme>

<!--TOOLTIP INFOS DENREES-->
<p-overlayPanel appendTo="body" #opInfosDenree [dismissable]="true">

  <div class="d-flex flex-column width-500" *ngIf="!utils.isNullOrEmpty(selectedLbd)">
    <div class="p-2">
      <strong>Déclinaison :</strong>
    </div>

    <div class="p-2">
      {{selectedLbd.produitDeclinaisonLibelle}}
    </div>

    <div class="p-2 font-italic font-12"
         *ngIf="selectedLbd.produitDeclinaisonId != selectedLbd.denreeInitialeId">
      La denrée '{{selectedLbd.denreeInitialeLibelle}}' est interdite pour l'unité de production
      '{{selectedLbd.uniteDeProductionLibelle}}'. Elle a été remplacée par
      '{{selectedLbd.produitDeclinaisonLibelle}}'.
    </div>


    <ng-container *ngFor="let platDemandeur of platsDemandeurs; let first=first; ">
      <div class="p-2" *ngIf="first">
        <strong>Plats demandeurs :</strong>
      </div>
      <div class="p-2">
        <yo-icon-produitfab [fabrique]="true"></yo-icon-produitfab>
        {{platDemandeur.libellePlat}} <span class="font-8">{{platDemandeur.libelleOffreAlimentairePrestation}} - {{ platDemandeur.repas }}</span> , <span
        class="font-italic font-12">{{platDemandeur.nbParts}}
        {{platDemandeur.abrvUniteMesurePlat}}, {{platDemandeur.quantiteDenreeUTFicheTechnique}} {{platDemandeur.abrvUniteMesureDenree}}</span>
      </div>
    </ng-container>

  </div>

</p-overlayPanel>

<!--BULK EDITION ARTICLE-->
<yo-bulk-edition-article
  (onClose)="onCloseBulkEditionArticleDialog()"
  (emitBulk)="onEmitBulkDetailSupplier($event)"
  [displayDialog]="displayBulkEditionArticleDialog">
</yo-bulk-edition-article>


