// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-11009(3c7bc96f69)-C10/12/2024-15:48:45-B10/12/2024-15:52:57' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-11009(3c7bc96f69)-C10/12/2024-15:48:45-B10/12/2024-15:52:57",
  branch: "master",
  latestTag: "6.1",
  revCount: "11009",
  shortHash: "3c7bc96f69",
  longHash: "3c7bc96f694d30351f9ba606222ec6c9d65ae059",
  dateCommit: "10/12/2024-15:48:45",
  dateBuild: "10/12/2024-15:52:57",
  buildType: "Ansible",
  } ;
