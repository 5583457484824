import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilsService} from '../../../core/utils/utils.service';
import {
  EMAIL_STATUT,
  BCF_STATUT,
  DATEPICKER_FR,
  HELP_FOLDERS,
  MSG_KEY,
  MSG_SEVERITY,
  TYPE_LIST_UDP
} from '../../../core/constants';
import {BonCfDTO} from '../../../core/dtos/boncfs-dto';
import {CommandesService} from '../../../core/services/gestion-commandes/commandes.service';
import {MenuItem} from 'primeng/api';
import {SearchSupplierWrapper} from '../../../core/suppliers/wrappers/search-supplier-wrapper';
import {SearchSupplier} from '../../../core/suppliers/search-supplier';
import {switchMap} from 'rxjs/operators';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {cloneDeep as _cloneDeep, uniqBy as _uniqBy} from 'lodash'
import {BonCfDetailDTO} from '../../../core/dtos/boncf-detail-dto';
import {ImpressionService} from '../../../core/services/impression.service';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {UnitesDeProductionService} from '../../../core/services/entities/unites-de-production.service';
import {UniteDeProductionDTO} from '../../../core/dtos/unite-de-production-dto';
import {RoutemapService} from '../../../core/services/routemap.service';
import {ValueDTO} from '../../../core/dtos/value-dto';
import {saveAs as fs_saveAs} from 'file-saver';
import {WorkflowsService} from '../../../core/services/entities/workflows.service';
import {DomSanitizer} from '@angular/platform-browser';
import {DialogMsgSupplier, Paragraphe} from '../../../core/suppliers/dialog-msg-supplier';
import {BoncfService} from '../../../core/services/entities/boncf.service';
import {of, Subscription} from 'rxjs';
import {MailHistoService} from '../../../core/services/entities/mail-histo.service';
import {BoncfDetailService} from '../../../core/services/entities/boncf-detail.service';
import {BesoinsService} from "../../../core/services/gestion-commandes/besoins.service";
import {DxDataGridComponent} from "devextreme-angular";
import CustomStore from "devextreme/data/custom_store";
import {Sort} from "../../../core/suppliers/generics/generic-request-supplier";
import {DevextremeService, FilterItem} from "../../../core/services/technique/devextreme.service";
import {ToastService} from "../../../core/services/technique/toast.service";
import {DuplicationBonCfComponent} from "../duplication-proposition/duplication-bon-cf.component";
import {MergeBonCfComponent} from "../merge-proposition/merge-bon-cf.component";
import {MailHistoDTO} from "../../../core/dtos/mail-histo-dto";

@Component({
  selector: 'yo-bc-propositions',
  templateUrl: './bc-propositions.component.html',
  styleUrls: ['./bc-propositions.component.scss']
})
export class BcPropositionsComponent implements OnInit, OnDestroy {

  subBonCf: Subscription;
  subMailHistoBonCf: Subscription;
  subRefreshGridCommandeProposition: Subscription;

  createCommandeOptionList: MenuItem[] = [];

  propositionsCommandes: BonCfDTO[];
  totalRecords = 0;
  loadingTable = false;
  displayEditDialog = false;
  dialogTitle = 'Détails';
  bonCf: BonCfDTO;
  selectedBonCfs: BonCfDTO[];
  lignesBonCf: BonCfDetailDTO[];

  BONCF_MAIL_STATUT = EMAIL_STATUT;

  // afficher le dialogue pour créer une nouvelle proposition de commande
  displayAddDialog = false;
  uniteDeProductionList: UniteDeProductionDTO[];

  displayMergeDialog: boolean = false;

  displayDuplicationDialog: boolean = false;

  // items pour le filtre de sélection des listes de besoin
  filterItemsListeBesoin: ValueDTO[];
  // items pour le filtre de sélection des unités de production
  filterItemsUdp: ValueDTO[];
  // items pour le filtre de sélection des fournisseurs
  filterItemsFournisseur: ValueDTO[];

  filterDatesLivraisons: Date[];

  fr: any;

  subMailsBonCfEnvoyes: Subscription;

  nextSteps: MenuItem[] = [
    {
      label: `Passer les propositions sélectionnées à l'étape suivante :`,
      items: [
        {
          label: 'À valider',
          icon: 'fas fa-angle-double-right',
          command: (event) => this.updateToCommandeConfirmees()
        },
        {
          label: 'Refusées',
          icon: 'fas fa-times',
          command: (event) => this.updateToCommandeRefusees()
        },
      ]
    },
  ];

  @ViewChild("grid", {static: false}) grid: DxDataGridComponent;

  dataSource: CustomStore;
  allMode: string;
  checkBoxesMode: string;

  listesBesoinsIdsSelected: number[] = [];
  udpIdsSelected: number[] = [];
  fournisseursIdsSelected: number[] = [];

  isActionDisabled: boolean = true;
  isSendMailDisabled: boolean = true;

  pathFile: string = HELP_FOLDERS.COMMANDES_FOURNISSEURS + '/proposition-commande';

  selectedBonCfsToMerge: BonCfDTO[];
  selectedBonCfToDuplicate: BonCfDTO;
  selectedUniteDeProductionToMerge: UniteDeProductionDTO;
  selectedUniteDeProductionToDuplicate: UniteDeProductionDTO;

  displayNoCorrespondancePopup: boolean = false;
  noCorrespondanceList: BonCfDetailDTO[] = [];
  reassignedBonCf: BonCfDTO;

  subReassignBonCf: Subscription;

  @ViewChild("mergeComponent") mergeComponent: MergeBonCfComponent;

  @ViewChild("duplicationComponent") duplicationComponent: DuplicationBonCfComponent;

  displayPopupSendMail: boolean = false;
  passToModeAReceptionner: boolean = true;

  selectAll: boolean = false;
  numeroCommandeSelected: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private cd: ChangeDetectorRef,
              private routeMapSvc: RoutemapService,
              private impressionSvc: ImpressionService,
              public auth2Svc: Auth2Service,
              private bonCfSvc: BoncfService,
              private besoinSvc: BesoinsService,
              public commandesSvc: CommandesService,
              private bonCfDetailSvc: BoncfDetailService,
              private gds: GenericDatagridService,
              private udpSvc: UnitesDeProductionService,
              public mailHistoSvc: MailHistoService,
              public wkfSvc: WorkflowsService,
              public utils: UtilsService,
              public domSanitizer: DomSanitizer,
              private dxSvc: DevextremeService,
              private toastSvc: ToastService) {
  }


  ngOnInit() {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
    this.loadingTable = true;
    this.fr = DATEPICKER_FR;

    this.bonCfSubscription();
    this.mailsBonCfEnvoyesSubscription();
    this.initCreateCommandeOption()
    this.subcriptionRefreshGrid();
    this.initCustomStore();
    this.initFilters();
    this.initUniteDeProductionList();
    this.openBonCfResultSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subBonCf);
    this.utils.unsubscribe(this.subMailHistoBonCf);
    this.utils.unsubscribe(this.subMailsBonCfEnvoyes);
    this.utils.unsubscribe(this.subRefreshGridCommandeProposition);
    this.utils.unsubscribe(this.subReassignBonCf);
  }

  initCreateCommandeOption = (): void => {
    this.createCommandeOptionList = [
      {
        label: `Passer une commande`,
        items: [
          {
            label: 'Par fournisseur',
            icon: 'fas fa-truck',
            command: (event) => this.showAddDialog()
          },
          {
            label: 'Par denrée',
            icon: 'fas fa-carrot',
            command: (event) => this.showAddDialogByDenree()
          }
        ]
      }
    ];
  };

  initFilters = (): void => {
    this.commandesSvc.getFilterItemsListeBesoin(BCF_STATUT.PROPOSITION_COMMANDE).subscribe(response => {
      this.filterItemsListeBesoin = response.resultList;
    });

    this.commandesSvc.getFilterItemsUdp(BCF_STATUT.PROPOSITION_COMMANDE).subscribe(response => {
      this.filterItemsUdp = response.resultList;
    });

    this.commandesSvc.getFilterItemsFfs(BCF_STATUT.PROPOSITION_COMMANDE).subscribe(response => {
      this.filterItemsFournisseur = response.resultList;
    });
  }

  onPropositionAdded = async (): Promise<void> => {
    await this.grid.instance.refresh();
  }

  onChangeFilterDateLivraison = async (): Promise<void> => {
    if (this.grid)
      await this.grid.instance.refresh();
  };

  onDateFilterValueChange = (dates): void => {
    this.filterDatesLivraisons = dates
    this.onChangeFilterDateLivraison();
  };

  onChangeFilterFournisseurs = async ($event): Promise<void> => {
    if (this.grid) {
      this.fournisseursIdsSelected = $event.value;
      await this.grid.instance.refresh();
    }
  };

  onChangeFilterUdps = async ($event): Promise<void> => {
    if (this.grid) {
      this.udpIdsSelected = $event.value;
      await this.grid.instance.refresh();
    }
  };

  onChangeFilterListesBesoins = async ($event): Promise<void> => {
    if (this.grid) {
      this.listesBesoinsIdsSelected = $event.value;
      await this.grid.instance.refresh();
    }
  };

  onChangeFilterNumCommande = async ($event: any): Promise<void> => {
    if (this.grid) {
      this.numeroCommandeSelected = $event.value;
      await this.grid.instance.refresh();
    }
  };

  checkDisabledButtons = async (): Promise<void> => {
    this.isActionDisabled = (await this.grid.instance.getSelectedRowsData()).length === 0 ;
    this.checkPreviousDateBcSelected();

  }

  initCustomStore = (): void => {
    this.dataSource = new CustomStore({
      key: 'id',
      load: (loadOptions: any) => {
        const pageSize: number = loadOptions.take || this.grid.instance.pageSize();
        const page: number = this.grid.instance.pageIndex();
        const sorts: Sort[] = this.dxSvc.dxToGrsSorts(loadOptions.sort);
        const ssWrapper = new SearchSupplierWrapper();
        const filters: FilterItem[] = this.dxSvc.dxToGrsFilters(loadOptions.filter);

        ssWrapper.filtersMap['statutCode'] = new SearchSupplier(BCF_STATUT.PROPOSITION_COMMANDE);
        if (this.numeroCommandeSelected)
          ssWrapper.filtersMap['numeroBcf'] = new SearchSupplier(this.numeroCommandeSelected);
        if (!this.utils.isCollectionNullOrEmpty(this.filterDatesLivraisons)) {
          const startDate = this.filterDatesLivraisons[0].getTime();
          let stopDate = _cloneDeep(startDate);
          if (this.filterDatesLivraisons[1]) {
            stopDate = this.filterDatesLivraisons[1].getTime();
          }
          ssWrapper.filtersMap['startDateLivraison'] = new SearchSupplier(startDate);
          ssWrapper.filtersMap['stopDateLivraison'] = new SearchSupplier(stopDate);
        }

        if (this.listesBesoinsIdsSelected && this.listesBesoinsIdsSelected.length)
          ssWrapper.filtersMap['listesBesoins'] = new SearchSupplier(undefined, this.listesBesoinsIdsSelected);
        if (this.udpIdsSelected && this.udpIdsSelected.length)
          ssWrapper.filtersMap['udps'] = new SearchSupplier(undefined, this.udpIdsSelected);
        if (this.fournisseursIdsSelected && this.fournisseursIdsSelected.length)
          ssWrapper.filtersMap['ffs'] = new SearchSupplier(undefined, this.fournisseursIdsSelected);
        let urlPaginationParams = this.gds.getUrlPaginationParamsFromDataGridDx(pageSize, page, sorts);
        if (loadOptions && loadOptions.select && loadOptions.select[0] === 'id' && this.selectAll) {
          // Si je coche tout => Il faut omettre la pagination
          urlPaginationParams = this.gds.getUrlPaginationParamsFromDataGridDx(null, null, sorts);
        }

        return this.commandesSvc.searchCommandes(ssWrapper, urlPaginationParams).toPromise().then(response => {
          this.propositionsCommandes = response.resultList;

          const resultSelectedRows = this.dxSvc.getRowsSelectedForDeferredMode(filters, response.resultList);
          if (resultSelectedRows) return resultSelectedRows;

          return {
            data: response.resultList,
            totalCount: response.totalElements
          }
        });
      },
      update: (key, values) => {
        return null;
      }
    });
  }

  initUniteDeProductionList = (): void => {
    this.udpSvc.getUniteDeProductionlist(TYPE_LIST_UDP.OnlyLocal).subscribe(response => {
      this.uniteDeProductionList = response.resultList;
      if (this.utils.isCollectionNullOrEmpty(this.uniteDeProductionList)) {
        this.uniteDeProductionList = [];
      }
      this.uniteDeProductionList = this.uniteDeProductionList.filter(udp => this.auth2Svc.isSiteLocal(udp.site.id));
    });
  }

  sendEmail = async (event: any): Promise<void> => {
    const selectedBonCfs: BonCfDTO[] = this.grid.instance.getSelectedRowsData();
    if (event) await this.updateToCommandeAReceptionner();

    setTimeout(() => {
      this.commandesSvc.sendMailToFournisseurs(selectedBonCfs);
    }, 1000);

    this.displayPopupSendMail = false;
  };

  onCloseSendMail = ($event: boolean) => {
    this.displayPopupSendMail = $event;
  };

  subcriptionRefreshGrid = (): void => {
    this.subRefreshGridCommandeProposition = this.commandesSvc.refreshGridCommandeProposition$.subscribe(async () => {
      await this.grid.instance.refresh();
    });
  }

  /**
   * Abonnement à la modification  d'un bon de commande, notamment pour mettre à jour le montant HT
   */
  bonCfSubscription = (): void => {
    this.subBonCf = this.bonCfSvc.bonCf$.subscribe(async () => {
      await this.grid.instance.refresh();
    });
  };

  /**
   * Abonnement à l'envoi d'un ou plusieurs bons de commandes par email afin de mettre à jour le statut du bon de commande.
   */
  mailsBonCfEnvoyesSubscription = (): void => {
    this.subMailsBonCfEnvoyes = this.commandesSvc.mailsBonCfEnvoyes$
      .pipe(
        switchMap(bonsCfDTO => {
          return this.gds.search(this.commandesSvc.filterStatusMails(bonsCfDTO));
        }),
        switchMap(response => {
          this.commandesSvc.updateStatusBonsCommandesFromRefresh(response.resultList, this.propositionsCommandes, true);
          return of(response.resultList);
        }),
        switchMap((mailsEnvoyes) => this.commandesSvc.refreshStatusMailsPeriodically(mailsEnvoyes, this.propositionsCommandes))
      )
      .subscribe(async (response) => {
        await this.grid.instance.refresh();
        const mails = _uniqBy(response.resultList, mail => mail.extraInfos);
        this.commandesSvc.updateStatusBonsCommandesFromRefresh(mails, this.propositionsCommandes, false);
      });
  };

  printPDFCommandes = async (): Promise<void> => {
    const selectedBonCfs: BonCfDTO[] = await this.grid.instance.getSelectedRowsData();
    this.commandesSvc.printPDFCommandes(selectedBonCfs).subscribe(response => {
      const blob = new Blob([response], {type: 'application/pdf'});
      fs_saveAs(blob, 'propositions.pdf');
    });
  };

  prepareToMergeBonsCf = async (): Promise<void> => {
    this.selectedBonCfsToMerge = await this.grid.instance.getSelectedRowsData();

    this.selectedUniteDeProductionToMerge =
      this.uniteDeProductionList.find(udp => udp.id === this.selectedBonCfsToMerge.find(bonCf => bonCf.uniteDeProductionId).uniteDeProductionId);

    if (this.selectedUniteDeProductionToMerge)
      this.displayMergeDialog = true;
  }

  prepareToDuplicateBonCf = async (bonCf: BonCfDTO): Promise<void> => {
    this.selectedBonCfToDuplicate = bonCf;

    this.selectedUniteDeProductionToDuplicate =
      this.uniteDeProductionList.find(udp => udp.id === this.selectedBonCfToDuplicate.uniteDeProductionId);

    if (this.selectedUniteDeProductionToDuplicate)
      this.displayDuplicationDialog = true;
  }

  mergeBonsCf = (event): void => {
    this.displayMergeDialog = false;
    this.commandesSvc.mergeBonsCf(event, this.selectedBonCfsToMerge.map(item => item.id)).subscribe(async response => {
      if (!this.utils.isResponseSupplierError(response)) {
        await this.grid.instance.refresh();
      }
    });
  };

  duplicateBNonCf = (event): void => {
    this.displayDuplicationDialog = false;
    this.commandesSvc.duplicateBonCf(event, this.selectedBonCfToDuplicate?.id).subscribe(async response => {
      if (!this.utils.isResponseSupplierError(response)) {
        await this.grid.instance.refresh();
      }
    })
  }

  updateToCommandeRefusees = async (): Promise<void> => {
    const rowsSelected: number[] = await this.grid.instance.getSelectedRowKeys();
    this.commandesSvc.updateCommandesStatut(rowsSelected, BCF_STATUT.COMMANDE_REFUSEE).subscribe(async (response) => {
      if (!this.utils.isResponseSupplierError(response)) {
        await this.grid.instance.refresh();
      }
    });
  };


  updateToCommandeConfirmees = async (): Promise<void> => {
    const rowsSelected: number[] = await this.grid.instance.getSelectedRowKeys();
    this.commandesSvc.updateCommandesStatut(rowsSelected, BCF_STATUT.COMMANDE_A_VALIDER).subscribe(response => {
      if (!this.utils.isResponseSupplierError(response)) {
        this.grid.instance.refresh();
      }
    });
  };

  updateToCommandeAReceptionner = async (): Promise<void> => {
    const rowsSelected: number[] = await this.grid.instance.getSelectedRowKeys();
    this.commandesSvc.updateCommandesStatut(rowsSelected, BCF_STATUT.COMMANDE_A_RECEPTIONNER).subscribe(response => {
      if (!this.utils.isResponseSupplierError(response)) {
        this.grid.instance.refresh();
      }
    });
  };

  openEditFournisseurDialog = (bonCf: BonCfDTO, openAddArticlesFournisseurDialog: boolean): void => {
    this.bonCf = undefined;

    this.commandesSvc.findDetailsFromBonCf(bonCf).subscribe(response => {

      this.lignesBonCf = [];

      if (!this.utils.isResponseSupplierError(response)) {
        this.displayEditDialog = true;
        this.dialogTitle = this.commandesSvc.getCfTitle(bonCf);
        this.lignesBonCf = response.resultList;
        this.bonCf = _cloneDeep(bonCf);

        if (openAddArticlesFournisseurDialog) {
          this.bonCfDetailSvc.announceAvailableArticles(this.bonCf);
        }
      }
    });
  };

  deleteBonCfs = async (): Promise<void> => {
    const rowsSelected: number[] = await this.grid.instance.getSelectedRowKeys();
    const selectedBonCfs: BonCfDTO[] = await this.grid.instance.getSelectedRowsData();
    // on ne doit pas supprimer les bons de commandes des sites secondaires
    const bonCfsToDelete = selectedBonCfs.filter(boncf => this.auth2Svc.isSiteLocal(boncf.site.id));
    const bonCfsToNoNotDelete = selectedBonCfs.filter(boncf => !this.auth2Svc.isSiteLocal(boncf.site.id));

    if (!this.utils.isCollectionNullOrEmpty(bonCfsToNoNotDelete))
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Vous ne pouvez pas supprimer la/les commande(s) ${bonCfsToNoNotDelete.map(item => item.numeroBcf).join(', ')}. La/Les commande(s) appartiennent à un site référent.`);

    if (!this.utils.isCollectionNullOrEmpty(bonCfsToDelete)) {
      this.dxSvc.dxDeleteItemsConfirmation(rowsSelected, () => {
        this.commandesSvc.delete(bonCfsToDelete).subscribe(response => {
          if (!this.utils.isResponseSupplierError(response)) {
            this.grid.instance.refresh();
          }
        });
      });
    }
  };

  showAddDialogByDenree = (): void => {
    this.besoinSvc.announceOpenPropositionDialogDenree(true);
  };

  showAddDialog = (): void => {
    this.displayAddDialog = true;
  };


  newPropositionCommande = (bonCf: BonCfDTO): void => {
    this.displayAddDialog = false;
    if (this.utils.isCollectionNullOrEmpty(this.propositionsCommandes)) {
      this.propositionsCommandes = [];
    }

    this.propositionsCommandes.unshift(bonCf);
    this.selectedBonCfs = [];
    this.selectedBonCfs.push(bonCf);

    // on incremente de 1 le nombre total d'éléments
    this.totalRecords = this.totalRecords + 1;

    // on ouvre les lignes de la proposition de commande.
    this.openEditFournisseurDialog(bonCf, true);
  };

  /**
   * Indique si le BonCfDTO est modifiable.
   * @param bonCF
   */
  canModify = (bonCF: BonCfDTO): boolean => this.gds.canModify(bonCF);

  help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Gérer les commandes fournisseur`;
    dms.logo = 'fa fa-question-circle  yoni-color';

    const p1: Paragraphe = new Paragraphe();
    p1.title = ` Création d’une <b>proposition de commande</b>`;
    p1.lines = [
      `Après avoir initié une création de commandes vous devrez renseigner les rubriques indiquées pour générer une proposition de commande.`,
      `Vous pourrez alors ajouter des articles à cette commande en précisant les quantités souhaitées en Unités de Commandes, voire ajouter une remarque pour un article donné ou encore une remarque d’ordre général concernant la commande.`,
      `N’oubliez pas d’enregistrer votre commande avant de quitter une fenêtre.`,
    ];

    dms.content = {
      intro: `Les <b>commandes fournisseur</b> fonctionnent selon une logique de « workflow » : cela signifie qu’une commande passera par une succession d’états pour aller d’une proposition de commandes qui constitue le 1er état jusqu’à un bon à facturer qui est le dernier état.
      <ul>
<li>Proposition de commande</li>
<li>Commande à valider</li>
<li>Commande à réceptionner</li>
<li>Commande réceptionnée</li>
<li>Commande facturée</li>
</ul>
      Après avoir sélectionné une ou plusieurs commandes fournisseurs, vous pourrez les faire passer à l’état suivant.`,
      paragraphes: [p1]
    };
    return dms;
  };

  openHistoriqueMails = (bonCf: BonCfDTO) => {
    this.bonCfSvc.announceHistoriqueMailBonCf(bonCf);
  };

  onCloseMerge = ($event: boolean) => {
    this.displayMergeDialog = $event;
    this.mergeComponent?.calendar?.instance?.dispose();
  };

  onCloseDuplication = ($event: boolean) => {
    this.displayDuplicationDialog = $event;
    this.duplicationComponent?.calendar?.instance?.dispose();
  };

  openBonCfResultSubscription = (): void => {
    this.subReassignBonCf = this.bonCfSvc.reassignBonCfResult$.subscribe(async (reassignResult: any) => {
      this.grid.instance.refresh().then(() => {
        let elementsSansCorrespondance: BonCfDetailDTO[] = reassignResult.elementsSansCorrespondance;

        this.reassignedBonCf = reassignResult.bonCommandeFournisseur;

        if (elementsSansCorrespondance && elementsSansCorrespondance.length > 0) {
          this.noCorrespondanceList = elementsSansCorrespondance;
          this.displayNoCorrespondancePopup = true;
        } else {
          this.openReassignedBonCf();
        }
      });
    });
  };

  openReassignDialog = (bonCf: BonCfDTO): void => {
    this.bonCfSvc.announceOpenReassignDialog(bonCf);
  };

  closeNoCorrespondancePopup = (displayBon: boolean): void => {
    this.displayNoCorrespondancePopup = false;
    if (displayBon)
      this.openReassignedBonCf();
  }

  openReassignedBonCf = (): void => {
    this.openEditFournisseurDialog(this.propositionsCommandes.find(bon => bon.numeroBcf === this.reassignedBonCf.numeroBcf), false);
  }
  openPopupSendMail = () => this.displayPopupSendMail = true;

  cellClick = (e: any) => this.selectAll = (e.rowType === 'header');


  isConditionneParInError(data) {
    return data.details.some(detail => detail.conditionnePar && detail.conditionnePar > 0 && this.utils.modulo(detail.quantiteACommanderAjustee, detail.conditionnePar) != 0)
  }

  isMinimumCommandInError(data) {
    return data.details.some(detail => detail.quantiteACommanderAjustee < detail.minimumDeCommande);
  }

  isFraisPortApplyed(data) {
    return this.bonCfDetailSvc.computeFrancoDePort(data, data.details);
  }

  onEmailHistoRretreived(emailHisto : MailHistoDTO) {
    this.propositionsCommandes.find(ligne => ligne.code === emailHisto.extraInfos).mailHisto = emailHisto;
  }

  private checkPreviousDateBcSelected() {
    if (this.grid.instance.getSelectedRowsData().length === 0)
      return false;
    this.isSendMailDisabled = this.grid.instance.getSelectedRowsData().some(bcf => this.utils.isDateBefore(bcf.dateLivraisonAjustee));
  }
}
