import {ObjectDTO} from "./object-dto";

export class AppellationDTO  extends ObjectDTO{


  code: string;
  libelle: string;
  actif:boolean;
  bio:boolean;
  durable:boolean;
  ordre: number;

}
